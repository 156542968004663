import React, { FC } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PriceLabel from './PriceLabel'
import { AppState } from '../../redux/reducers'
import { getCartPrice, getOrderPrice, getGroupedAndSortedOrderProducts, OrderProductGroup } from '../../selectors/orderSelectors'
import ShoppingCartLine from './ShoppingCartLine'
import { OrderProduct } from '../../api/orderApi'
import ShoppingCartTitle from './ShoppingCartTitle'
import { Col, Translate, RTEContent } from '@jstack/libema-design-system'
import _ from 'lodash'
import { BasketInfoLine } from '../../api/pageApi'
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/solid'

const SummaryBlock = styled.div`
  background-color: ${({ theme }) => theme.color.neutralDark};
  color: #fff;
  padding: ${({ theme }) => theme.padding.md};
`

const PriceBlock = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  padding: ${({ theme }) => theme.padding.md};

  & > span:first-of-type {
    font-size: 1.5rem;
  }
`

const Section = styled.div`
  margin-left: 16px;
`

const InfoLineContainer = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.margin.md};
  }
`

const StyledCheckIcon = styled(CheckIcon)`
  color: ${({ theme }) => theme.color.success};
  width: 25px;
  height: 25px;
  margin-right: ${({ theme }) => theme.margin.md};
  flex-shrink: 0;
`

const StyledExclamationIcon = styled(ExclamationCircleIcon)`
  color: ${({ theme }) => theme.color.error};
  width: 25px;
  height: 25px;
  margin-right: ${({ theme }) => theme.margin.md};
  flex-shrink: 0;
`

interface ShoppingCartProps {
  // This defines if we must display "final" data that we received from ReCreateX
  // If this is undefined or false, we fall back to our own calculated total prices
  isFinal?: boolean
  orderPrice: number
  orderProducts: OrderProductGroup[]
  totalPrice: number
  label?: string
  basketInfoLines?: BasketInfoLine[]
}

type GroupProps = OrderProductGroup & {
  isFinal?: boolean
}

type TimeframeGroup = {
  identifier: string
  from?: string
  until?: string
}

const Group: FC<GroupProps> = ({ id, from, until, items, label, isFinal }) => {
  let timeframeGroups: TimeframeGroup[] = []

  if (label) {
    timeframeGroups = _.uniqBy(items, 'period_id').map(({ period_id, period_from, period_until }) => ({
      identifier: period_id as string,
      from: period_from,
      until: period_until,
    }))
  }

  const separatedByGroup = timeframeGroups.length > 0

  return (
    <React.Fragment>
      <ShoppingCartTitle identifier={id} from={from} until={until} label={label} />
      {separatedByGroup &&
        timeframeGroups.map((group) => (
          <Section key={group.identifier}>
            <ShoppingCartTitle {...group} />
            {items
              .filter((i) => i.period_id === group.identifier)
              .map((product: OrderProduct) => (
                <ShoppingCartLine
                  canBeRemoved={isFinal !== true}
                  key={`${product.id}-${product.period_id}`}
                  id={product.id}
                  product={product.name}
                  timeframeId={product.period_id}
                  quantity={product.quantity}
                  voucher={product.voucher}
                />
              ))}
          </Section>
        ))}
      {!separatedByGroup &&
        items.map((product: OrderProduct) => (
          <ShoppingCartLine
            canBeRemoved={isFinal !== true}
            key={`${product.id}-${product.period_id}`}
            id={product.id}
            product={product.name}
            timeframeId={product.period_id}
            quantity={product.quantity}
            voucher={product.voucher}
          />
        ))}
    </React.Fragment>
  )
}

const ShoppingCart: React.FC<ShoppingCartProps> = ({ isFinal, orderPrice, orderProducts, totalPrice, basketInfoLines }) => {
  return (
    <>
      <Col col={12}>
        <SummaryBlock>
          {orderProducts.map((orderProductGroup: OrderProductGroup) => {
            return <Group {...orderProductGroup} isFinal={isFinal} key={orderProductGroup.id} />
          })}
          {Object.keys(orderProducts).length === 0 && <Translate id="warning.no_products" />}
        </SummaryBlock>
      </Col>
      <Col>
        <PriceBlock data-class="price-block">
          <span>
            <Translate id="title.total" />
            :&nbsp;
          </span>
          <PriceLabel value={isFinal === true ? totalPrice : orderPrice} />
        </PriceBlock>
      </Col>
      {basketInfoLines && basketInfoLines.length > 0 && (
        <Col>
          <SummaryBlock>
            {basketInfoLines.map((line, index) => (
              <InfoLineContainer key={index}>
                {line.icon === 'check' && <StyledCheckIcon />}
                {line.icon === 'exclamation' && <StyledExclamationIcon />}
                <RTEContent dangerouslySetInnerHTML={{ __html: line.description }} />
              </InfoLineContainer>
            ))}
          </SummaryBlock>
        </Col>
      )}
    </>
  )
}

const mapState = (state: AppState) => ({
  orderPrice: getOrderPrice(state),
  orderProducts: getGroupedAndSortedOrderProducts(state),
  totalPrice: getCartPrice(state),
})

export default connect(mapState)(ShoppingCart)
