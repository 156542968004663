import { createSelector } from 'reselect'
import { AppState } from '../redux/reducers'
import { CartItem, CustomerState } from '../redux/orderReducer'
import { Timeframe } from '../api/productApi'
import { OrderProduct, CombinedVoucherOrderProduct } from '../api/orderApi'
import productUtils, { getVoucherProductId } from '../utils/productUtils'
import { getTimeframe } from './timeframeSelectors'
import productTypes from '../constants/productTypes'

export const getCartItems = (state: AppState) => state.order.cart.items || []

export const getCartPrice = (state: AppState): number => state.order.cart.price || 0

export const getCustomerId = (state: AppState): string => state.order.cart.customerId || ''

export const getCustomer = (state: AppState): CustomerState | undefined => state.order.customer

export const getOrderProducts = (state: AppState): OrderProduct[] => (state.order.products || [])

export const getOrderProduct = (state: AppState, id: string, timeframeId?: string): OrderProduct | undefined => (
  state.order.products || []).find((order) => {
    let found = order.id === id;
    
    if (timeframeId) {
      found = found && order.period_id === timeframeId;
    }

    return found;
  }
)

export const hasValidatedCart = (state: AppState): boolean =>
  typeof state.order.cart.customerId === 'string' ||
  typeof state.order.id === 'string'

export interface OrderProductGroup {
  id: string // The timeframe_id
  from?: string
  until?: string
  label?: string
  items: OrderProduct[]
}

const getGroupIdentifier = (orderProduct: OrderProduct): string => {
  if (orderProduct.activity_group) {
    return orderProduct.exposition_id as string
  }

  if (typeof orderProduct.period_id !== 'undefined') {
    return orderProduct.period_id
  }

  if (orderProduct.type === productTypes.VOUCHER) {
    return 'vouchers'
  }

  return 'other'
}

export const getGroupedOrderProducts = createSelector(
  getOrderProducts,
  (orderProducts: OrderProduct[]): {[identifier: string]: OrderProductGroup} =>
    orderProducts.reduce(
      (grouped, orderProduct) => {
        const identifier = getGroupIdentifier(orderProduct)

        if (typeof grouped[identifier] === 'undefined') {
          return {
            ...grouped,
            [identifier]: {
              id: identifier,
              label: orderProduct.activity_group,
              from: orderProduct.period_from,
              until: orderProduct.period_until,
              items: [orderProduct],
            },
          }
        }
        
        return {
          ...grouped,
          [identifier]: {
            ...grouped[identifier],
            items: [...grouped[identifier].items, orderProduct]
          }
        }
      },
      {}
    )
)

export const getGroupedAndSortedOrderProducts = createSelector(
  getGroupedOrderProducts,
  (group: {[timeframeId: string]: OrderProductGroup}): OrderProductGroup[] =>
    Object.keys(group)
      .map((key: string) => group[key])
      .sort((a: OrderProductGroup, b: OrderProductGroup) => {
        if (typeof a.from === 'undefined' || typeof a.label !== 'undefined') {
          return 1
        }
        if (typeof b.from === 'undefined' || typeof b.label !== 'undefined') {
          return -1
        }

        if (a.from < b.from) {
          return -1
        }

        if (a.from > b.from) {
          return 1
        }

        return 0
      })
)

export const getOrderPrice = createSelector(
  getOrderProducts,
  (orderProducts: OrderProduct[]): number => {
    if (!orderProducts.length) {
      return 0
    }

    return orderProducts
      .reduce(
        (total: number, item: OrderProduct) => total + (item.price * item.quantity),
        0
      )
  }
)

export const isReadyForCheckout = createSelector(
  getOrderProducts,
  (orderProducts: OrderProduct[]): boolean => orderProducts.length > 0
)

export const isReadyForConfirmation = createSelector(
  getOrderProducts,
  getCartItems,
  getCustomer,
  (orderProducts: OrderProduct[], cartItems: CartItem[], customer: CustomerState | undefined): boolean =>
    orderProducts.length > 0
    && cartItems.length > 0
    && typeof customer !== 'undefined'
    && 'email' in customer
    && 'first_name' in customer
    && 'last_name' in customer
    && 'terms' in customer
)

export const getOrderProductsWithVoucher = createSelector(
  getOrderProducts,
  (products: OrderProduct[]): OrderProduct[] => products.filter((product) => typeof product.voucher !== 'undefined')
)

export const getCombinedOrderProductsWithVoucher = createSelector(
  getOrderProductsWithVoucher,
  (products: OrderProduct[]): CombinedVoucherOrderProduct[] => Object.values(products.reduce(
    (combined: {[id: string]: CombinedVoucherOrderProduct }, product: OrderProduct) => {
      const id = getVoucherProductId(product.id)
      if (typeof combined[id] !== 'undefined') {
        combined[id].quantity = combined[id].quantity + 1
        if (typeof product.voucher !== 'undefined') {
          combined[id].vouchers.push(product.voucher)
        }
      } else {
        const vouchers = typeof product.voucher !== 'undefined' ? [product.voucher] : []
        combined[id] = { ...product, id, vouchers }
        delete combined[id].voucher
      }
      return combined
    },
    {}
  ))
)

/**
 * Get the first available exposition of an order.
 * In theory an order could hold products of multiple expositions, but since this is not a real usecase
 * in the ticketshops, we ignore that fact for now.
 */
export const getOrderExposition = createSelector(
  getOrderProducts,
  (products: OrderProduct[]): string | undefined => {
    const productWithExposition = products.find((product: OrderProduct) => typeof product.exposition_id !== 'undefined')
    if (typeof productWithExposition === 'undefined') {
      return undefined
    }

    return productWithExposition.exposition_id
  }
)

export const getTotalTicketsByExposition = (state: AppState, expositionId: string) =>
  productUtils.totalTicketsByExposition(getOrderProducts(state), expositionId);

export const getTotalTimeframeTickets = createSelector(
  getOrderProducts,
  getTimeframe,
  (products: OrderProduct[], timeframe: Timeframe): number => {
    if (typeof timeframe === 'undefined') {
      return 0
    }

    return products
      .filter((product: OrderProduct) => product.period_id === timeframe.id || product.main_period_id === timeframe.id)
      .reduce((total: number, product: OrderProduct) => total + product.quantity, 0)
  }
)

export const getOrderProductQuantity = createSelector(
  getOrderProduct,
  (product: OrderProduct | undefined) => {
    if (typeof product === 'undefined') {
      return 0
    }
    return product.quantity
  }
)
