import React from 'react'
import { Link } from 'react-router-dom'
import routeUtils from '../../utils/routeUtils'
import { Alert, AlertType, Translate } from '@jstack/libema-design-system'

const OrderInvalid: React.FC = () => (
  <Alert type={AlertType.ERROR}>
    <Translate id="error.checkout_invalid" /><br />
    <Link to={routeUtils.getShopEntry()}>
      <Translate id="button.order_edit" />
    </Link>
  </Alert>
)

export default OrderInvalid
