export default {
  // Buttons
  'button.add': 'Hinzufügen',
  'button.automatic_redirect': 'Bitte hier klicken, wenn Sie nicht sofort weitergeleitet werden.',
  'button.checkout_next': 'Bestellung fortsetzen',
  'button.disclaimer': 'Disclaimer',
  'button.download_tickets': 'Tickets herunterladen',
  'button.order_edit': 'Bestellung ändern',
  'button.order_new': 'Neue Bestellung',
  'button.park_regulations': 'Park-Richtlinien',
  'button.payment_submit': 'Abschließen',
  'button.privacy_statement': 'Datenschutzerklärung',
  'button.remove': 'Entfernen',
  'button.to_products': 'Zu den Tickets',
  'button.voucher_redeem': 'Kontrollieren',
  'button.voucher_additional': 'Noch ein Code',

  // Errors
  'error.api.timeframe_expired': 'Eines der ausgewählten Zeitfenster wurde geschlossen. Wählen Sie ein anderes Zeitfenster',
  'error.api.timeframe_full': 'Eins der ausgewählten Zeitfenster ist leider ausgebucht. Bitte wählen Sie ein anderes Zeitfens ter',
  'error.api.unexpected': 'Es ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder nehmen Sie telefonisch Kontakt auf unter {phoneNumber}',
  'error.api.validation_failed':
    'Bei Ihrer Bestellung ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder nehmen Sie telefonisch Kontakt auf unter {phoneNumber}',
  'error.checkout_invalid': 'Ihre Bestellung ist noch nicht vollständig. Ändern Sie Ihre Bestellung und bestellen Sie erneut.',
  'error.max_tickets_reached': 'Sie haben die maximale Anzahl von Tickets pro Person erreicht.',
  'error.max_timeframe_reached': 'Die maximale Anzahl von Tickets für dieses Zeitfenster ist erreicht.',
  'error.max_tickets_exceeded': 'Sie haben die maximale Anzahl an Tickets überschritten.',
  'error.max_tickets_product_quantity': 'Sie haben die maximale Anzahl an Tickets für {title} erreicht',
  'error.order_confirmation_failed': 'Bei der Auftragsbestätigung ist ein Fehler aufgetreten',
  'error.paymentmethods_fetch_failed': 'Beim Abrufen der Zahlungsmethoden ist ein Fehler aufgetreten.',
  'error.products_fetch_failed': 'Beim Abrufen der Produkte ist ein Fehler aufgetreten. Versuchen Sie es erneut oder kontaktieren Sie uns unter {phoneNumber}',
  'error.voucher_already_exists': 'Sie haben diesen Gutscheincode schon eingegeben',
  'error.voucher_redeem_failed': 'Leider ist dieser Gutscheincode ungültig',
  'error.voucher_validation_failed': 'Bei der Validierung Ihres Gutscheincodes ist ein Fehler aufgetreten',
  'error.voucher_maximum_vouchers_reached': 'Sie haben die maximale Anzahl von Gutscheincodes erreicht',

  // Forms
  'form.customer.subtitle': 'Wir benötigen die folgenden Informationen, um Ihre Tickets digital zu versenden',
  'form.customer.title': 'Persönliche Informationen',

  // Form help
  'help.email': 'Ihre Tickets werden an diese E-Mail-Adresse gesendet. Nicht erhalten, überprüfen Sie Ihre Junk-Mail.',

  // Form labels
  'label.agree_with': 'Sie müssen einverstanden sein mit',
  'label.almost_sold_out': 'Fast voll',
  'label.and': 'und',
  'label.closed': 'Geschlossen',
  'label.email': 'E-Mail-Adresse',
  'label.email_verify': 'E-Mail-Adresse (überprüfung)',
  'label.fields_required': '* Diese Felder sind Pflichtfelder',
  'label.first_name': 'Vorname',
  'label.last_name': 'Familienname',
  'label.legend': 'Legende',
  'label.newsletter':
    '{name}  darf mich nach meinem Besuch und meinen Interessen entsprechend über die neuesten Aktionen, Abenteuer, Veranstaltungen und persönlichen Vorteile informieren.',
  'label.phone': 'Telefonnummer',
  'label.park_regulations': 'Park-Richtlinien',
  'label.privacy_policy': 'Datenschutz',
  'label.selected_day': 'Ausgewählter Tag',
  'label.sold_out': 'Voll',
  'label.terms': 'allgemeine Geschäftsbedingungen',
  'label.timeframe_from_util': 'Von {from} bis {until} Uhr',
  'label.today': 'Heute',
  'label.voucher_code': 'Gutscheincode',

  'placeholder.voucher': 'Ihr Gutscheincode',

  // Tickets
  'tickets.arrangements.title': 'Verfügbare Pakete am',
  'tickets.activities.title': 'Verfügbare Aktivitäten für',
  'tickets.activities.error':
    'Beim Abrufen der aktivitäten ({expositionId}) ist ein Fehler aufgetreten. Versuchen Sie es erneut oder kontaktieren Sie uns unter {phoneNumber}',

  // Routes
  'route.checkout': '/de/checkout',
  'route.payment_failed_aquazoo': '/de-fehlermeldung',
  'route.payment_failed_beeksebergen': '/de/fehlermeldung',
  'route.payment_failed_dierenrijk': '/de-fehlermeldung',
  'route.payment_failed_speelland': '/de/fehlermeldung',
  'route.payment_failed_zooparc': '/de-fehlermeldung',
  'route.payment_success_aquazoo': '/de-vielen-dank-fur-ihre-bestellung',
  'route.payment_success_beeksebergen': '/de/vielen-dank-fur-ihre-bestellung',
  'route.payment_success_dierenrijk': '/de-vielen-dank-fur-ihre-bestellung',
  'route.payment_success_speelland': '/de/vielen-dank-fur-ihre-bestellung',
  'route.payment_success_zooparc': '/de-vielen-dank-fur-ihre-bestellung',

  // Titles
  'title.404': 'Seite nicht gefunden',
  'title.404_subtitle': 'Diese Seite existiert nicht (mehr), oder es gibt einen Tippfehler in der Adresse. Prüfen Sie, ob die Adresse richtig geschrieben ist',
  'title.additional_products': 'Extras',
  'title.arrival_date': 'Wählen Sie Ihr Ankunftsdatum',
  'title.checkout_redirect': 'Zahlung verifizieren',
  'title.checkout_redirect_subtitle': 'Wir verifizieren Ihre Zahlung und leiten Sie gleich auf die Website weiter.',
  'title.free': 'Kostenlos',
  'title.libema_footer': 'Für die schönste Zeit des Jahres, {account} von',
  'title.order_verification_cancelled': 'Ups, das dauert zu lange.',
  'title.order_verification_cancelled_subtitle':
    'Ihre Zahlung wurde empfangen, aber Ihre Bestellung wurde noch nicht bearbeitet. Dies dauert länger als gewöhnlich. Wenn Sie innerhalb weniger Minuten keine E-Mail erhalten, kontaktieren Sie uns bitte unter +31 (0)88-9000360.',
  'title.payment_failed': 'Ups ... das hat nicht funktioniert!',
  'title.payment_failed_subtitle':
    'Leider hat etwas nicht funktioniert. Versuchen Sie es noch einmal! Brauchen Sie Hilfe? Rufen Sie uns an unter +31 (0)88-9000360 (Mo - Fr bis 18.00 Uhr, Sa - So bis 17.00 Uhr).',
  'title.payment_method': 'Wählen Sie eine Zahlungsmethode',
  'title.payment_method_subtitle': 'Um Ihre Buchung endgültig abzuschließen, müssen Sie nur noch bezahlen. Wählen Sie eine der Zahlungsmethoden.',
  'title.selected_date': 'Verfügbare Tickets am',
  'title.shopping_cart': 'Ihr Warenkorb',
  'title.tickets': 'Tickets bestellen',
  'title.tickets_subtitle_aviodrome':
    "Im Aviodrome vergeht die Zeit wie im Fluge! Kinder bis zum Alter von 3 Jahren 'fliegen' kostenlos ein. <br />Älter als 3 Jahre? Dann profitieren Sie von unserem Online-Rabatt.",
  'title.tickets_subtitle_aquazoo':
    'Sind Sie bereit für einen spritzigen Tag im AquaZoo? Kinder bis zu 3 Jahren können den Park kostenlos erkunden. <br />Älter als 3 Jahre? Dann profitieren unsere Gäste von unserem Online-Rabatt.',
  'title.tickets_subtitle_beeksebergen':
    'Sind Sie bereit, auf Safari zu gehen? Kinder bis zu 3 Jahren erhalten freien Eintritt. <br />Älter als 3 Jahre? Dann profitieren unsere Gäste von unserem Online-Rabatt.',
  'title.tickets_subtitle_dierenrijk':
    'Erleben Sie tierischen Spaß im Dierenrijk. Kinder bis zu 3 Jahren sind kostenlos dabei. <br />Älter als 3 Jahre? Dann profitieren unsere Gäste von unserem Online-Rabatt.',
  'title.tickets_subtitle_speelland':
    'Haben Ihre Kleinen Lust auf einen ganzen Tag voller Spielspaß? Kinder bis zu 3 Jahren erhalten freien Eintritt. Sind sie älter als 3 Jahre? Dann profitieren unsere Gäste von unserem Online-Rabatt.',
  'title.tickets_subtitle_zooparc':
    'Sind Sie bereit, eine Expedition zu unternehmen? Kinder bis zu 3 Jahren können den Park kostenlos erkunden. <br />Älter als 3 Jahre? Dann profitieren unsere Gäste von unserem Online-Rabatt.',
  'title.timeframes': 'Ankunftszeit',
  'title.timeframes_subtitle':
    'Unten sehen Sie, wann Ihr Abenteuer an dem von Ihnen gewählten Tag beginnen kann. Die späteste Ankunftszeit ist mindestens eine halbe Stunde vor Parkschluss.',
  'title.total': 'Summe',
  'title.unfinished_order':
    "Es ist leider nicht möglich, mehrere Bestellungen gleichzeitig aufzugeben. Schließen Sie zuerst die andere Bestellung ab oder klicken Sie auf 'Neue Bestellung'. Die andere Bestellung wird dann storniert.",
  'title.vouchers': 'Tickets mit Gutscheincodes',
  'title.vouchers_subtitle': 'Wenn Sie Gutscheincodes erhalten haben, tragen Sie diese nachstehend bitte ein',
  'title.your_order': 'Ihre Bestellung',
  'title.your_order_subtitle':
    'Beinage geschafft! Aber noch nicht ganz… Überprüfen Sie Ihre Bestellung, vervollständigen Sie Ihre Bestellung und erhalten Sie diese direkt in Ihre Mailbox.',

  // Form validators
  'validator.email_invalid': 'Ungültige E-Mail-Adresse',
  'validator.email_mismatch': 'Die E-Mail-Adressen stimmen nicht überein',
  'validator.email_required': 'Das E-Mail-Feld ist ein Pflichtfeld',
  'validator.first_name_required': 'Das Vorname-Feld ist ein Pflichtfeld',
  'validator.last_name_required': 'Das Nachname-Feld ist ein Pflichtfeld',
  'validator.payment_method_required': 'Wählen Sie bitte eine Zahlungsmethode',
  'validator.privacy_required': 'Sie müssen der Datenschutzerklärung zustimmen',
  'validator.products_required': 'Sie haben keine Produkte ausgewählt',
  'validator.required': 'Das ist ein Pflichtfeld',
  'validator.terms_required': 'Sie müssen den allgemeinen Geschäftsbedingungen zustimmen',
  'validator.phone_required': 'Das Telefonnummernfeld ist ein Pflichtfeld',

  // Warnings
  'warning.no_products': 'Keine Produkte ausgewählt',
  'warning.no_timeframes': 'Leider gibt es an diesem Tag keine Verfügbarkeit mehr. Suchen Sie sich gleich einen anderen Tag aus.',
  'warning.old_browser':
    'Sie verwenden einen <strong>veralteten</strong> Browser. Bitte <a>Aktualisieren Sie Ihren Browser</a>, um Nutzung und Sicherheit zu verbessern.',
  'warning.default.no_timeframes': 'Leider gibt es an diesem Tag keine Verfügbarkeit mehr. Suchen Sie sich gleich einen anderen Tag aus.',

  // Warnings (LightSafari)
  'warning.4B4E039C-BD9D-EE11-A2EB-CDA6323A24CE.no_timeframes':
    'Leider gibt es an diesem Tag keine Light Safari. Die Light Safari findet vom 28. November 2024 bis 9. März, Donnerstag bis Sonntag und an allen Feiertagen statt.',

  // Somerdrome
  'tickets.timeframe.tabs_day': 'Tageskarten',
  'tickets.timeframe.tabs_evening': 'Abendkarten',
  'tickets.timeframe_v2.tabs_day': '<strong>Tageseintritt</strong>',
  'tickets.timeframe_v2.tabs_evening': '<strong>Mittag Eintritt</strong>',
  'tickets.timeframe.tabs_day.subtitle': 'Zugang von 10.00 Uhr',
  'tickets.timeframe.tabs_evening.subtitle': 'Zugang von 15.00 Uhr',
  'tickets.timeframe.tabs_day.price': '<strong>Ab € 18,00</strong>',
  'tickets.timeframe.tabs_evening.price': '<strong>€ 17,50</strong>',
  'tickets.timeframe.tabs_evening.price_beeksebergen': '<strong>€ 16,50</strong>',
  'somerdrome.day.timeframes_subtitle':
    'Wenn Sie eine Ankunftszeit zwischen <strong>10:00</strong> und <strong>15:00</strong> Uhr wählen, haben Sie die Möglichkeit, sogar bis <strong>21:00</strong> Uhr im Safaripark Beekse Bergen zu bleiben.',
  'somerdrome.evening.timeframes_subtitle':
    'Wenn Sie eine Ankunftszeit zwischen <strong>15:00</strong> und <strong>20:00</strong> Uhr wählen, haben Sie sogar bis <strong>21:00</strong> Uhr Zugang zum Safaripark Beekse Bergen.',

  // Order Confirmation (BeekseBergen)
  'order_confirmation.success.banner.title': 'Gut gerüstet fürs Abenteuer',
  'order_confirmation.success.banner.subtitle':
    'Laden Sie schon jetzt die Beekse Bergen-App herunter. Dort finden Sie Informationen über alles, was es in Beekse Bergen zu erleben gibt. Das bedeutet auch, dass Sie den Lageplan immer zur Hand haben.',
  'order_confirmation.success.banner.button': 'Entdecken Sie die App',
  'order_confirmation.success.banner.button_url': 'https://www.beeksebergen.nl/de/app',

  // Order Confirmation (BrewAtTheZoo)
  'order_confirmation.brewatthezoo.success.title': 'Gelukt! Je bent erbij!',
  'order_confirmation.brewatthezoo.success.subtitle': `<div>
  <p style="margin-bottom: 8px">
    Heb jij ook zo'n zin in Brew@theZoo bij Safaripark Beekse Bergen? Om goed voorbereid op 'biersafari' te gaan, hebben wij nog een paar
    belangrijke punten op een rijtje gezet:
  </p>
  <ol style="list-style:decimal;margin-bottom:8px;padding-left:24px">
    <li>
      Je ontvangt je gekochte Brew@theZoo tickets en reserveringsbevestiging op het opgegeven e-mailadres. Let op: mocht je jouw tickets
      niet direct ontvangen, controleer dan of deze in je spam of ongewenste e-mail terecht zijn gekomen.
    </li>
    <li>Neem je tickets én reserveringsbevestiging mee naar het evenement.</li>
    <li>Heb je tickets met korting gekocht? Zorg dan dat je je abonnement of Attractiepas ook meeneemt.</li>
    <li>Op de hoogte blijven van het laatste nieuws in Beekse Bergen? Schrijf je in voor de <a href="https://www.beeksebergen.nl/nieuwsbrief">nieuwsbrief</a>.</li>
  </ol>
  <p style="margin-bottom: 8px">
    We gaan ons best doen om weer een onvergetelijke editie van Brew@theZoo neer te zetten samen met jou. Tot dan, proost!
  </p>
</div>`,
  'order_confirmation.brewatthezoo.success.banner.title': 'Blijven slapen tijdens Brew@theZoo?',
  'order_confirmation.brewatthezoo.success.banner.subtitle':
    'Dat kan! Overnacht tussen de wilde dieren op het Safari Resort, midden in de natuur op het Lake Resort of met hotelservice in het nieuwe Safari Hotel.',
  'order_confirmation.brewatthezoo.success.banner.button': 'Bekijk alle accommodaties',
  'order_confirmation.brewatthezoo.success.banner.button_url': 'https://www.beeksebergen.nl/overnachten/zoek-en-boek',
}
