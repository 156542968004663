import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import { Timeframe } from '../../api/productApi'
import { AppState } from '../../redux/reducers'
import { getAccount, getAccountId, getCalendarInfoMessage, getLocale } from '../../selectors/envSelectors'
import { ThunkDispatch } from '../../redux/types'
import { fetchTimeframes } from '../../actions/productActions'
import ProductDatesSkeleton from './ProductDatesSkeleton'
import { getExpositionDates, ExpositionDate } from '../../api/expositionApi'
import { Account, CalendarInfoMessage } from '../../redux/envReducer'
import { Alert, AlertType, DatePicker, AvailableDates, Translate, deviceUtils } from '@jstack/libema-design-system'

const StyledMessage = styled(Alert)`
  margin-bottom: 16px;
  font-size: 0.875rem;

  @media ${deviceUtils.DEVICE_MD_UP} {
    margin-left: -15px;
    margin-right: -15px;
  }

  & a {
    font-weight: bold;
  }
`
interface ProductDatesProps {
  account: Account | null
  accountId: string | null
  expositionId: string
  device?: string
  locale: string
  calendarInfoMessage?: CalendarInfoMessage
  fetchTimeframes: (params: object) => Promise<Timeframe[]>
  onDateChange: (date: moment.Moment | null) => void
}

interface ProductDatesState {
  dates: AvailableDates
  isError: boolean
  isFetching: boolean
}

class ProductDates extends React.PureComponent<ProductDatesProps, ProductDatesState> {
  elementRef = React.createRef<HTMLDivElement>()
  constructor(props: ProductDatesProps) {
    super(props)
    this.state = {
      dates: {},
      isError: false,
      isFetching: true,
    }
  }

  componentDidMount = () => {
    this.fetchDates(moment(), 'next')
  }

  fetchDates = (date: moment.Moment, direction: 'next' | 'prev') => {
    // let from
    // let until
    // if (direction === 'prev') {
    //   until = date.endOf('month').format('YYYY-MM-DD')
    //   from = date.clone().startOf('month').format('YYYY-MM-DD')
    // } else {
    //   from = date.startOf('month').format('YYYY-MM-DD')
    //   until = date.clone().add(1, 'month').endOf('month').format('YYYY-MM-DD')
    // }
    const from = date.startOf('month').format('YYYY-MM-DD')
    const until = date.clone().add(1, 'year').endOf('month').format('YYYY-MM-DD')

    getExpositionDates(this.props.accountId || '', this.props.expositionId, { from, until })
      .then((res: AxiosResponse<ExpositionDate[]>) => {
        if (process.env.RAZZLE_STAGE === 'testing' && window.location.search.includes('simulate_errors=true')) {
          throw new Error('Manual error to test error handling')
        }

        this.setState({
          dates: res.data.reduce<AvailableDates>((map: AvailableDates, periodDate: ExpositionDate) => {
            const formattedDate = periodDate.date.split('T')[0]
            return {
              ...map,
              [formattedDate]: periodDate.is_available,
            }
          }, {}),
          isFetching: false,
        })
        if (res.data.length) {
          this.selectDate(moment(res.data[0].date), true)
        }
      })
      .catch(() =>
        this.setState({
          isError: true,
          isFetching: false,
        })
      )
  }

  selectDate = (date: moment.Moment | null, initial: boolean = false) => {
    this.props.onDateChange(date)

    if (!initial && this.elementRef.current !== null) {
      window.scrollTo({
        top: deviceUtils.getDistanceToTop(this.elementRef.current),
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  isDayFull = (day: moment.Moment): boolean => {
    const date = this.state.dates[day.format('YYYY-MM-DD')]
    if (typeof date === 'undefined') {
      return false
    }

    if (date === true) {
      return false
    }

    return true
  }

  render = () => {
    const { dates, isError, isFetching } = this.state
    const { device, locale, calendarInfoMessage } = this.props

    if (isError) {
      return (
        <Alert type={AlertType.ERROR}>
          <Translate id="error.timeframes_fetch_failed" values={{ phoneNumber: this.props.account?.phone_number }} />
        </Alert>
      )
    }

    if (isFetching || !dates || Object.keys(dates).length < 1) {
      return <ProductDatesSkeleton />
    }

    return (
      <div ref={this.elementRef} data-class="date-picker">
        <DatePicker
          availableDates={dates}
          device={device}
          initialDate={moment(Object.keys(dates)[0])}
          isDayHighlighted={this.isDayFull}
          onDateChange={this.selectDate}
          // We currently fetch the whole year at once, but this callback
          // can be used to fetch data per month.
          // onMonthChange={this.fetchDates}
        />
        {calendarInfoMessage && (
          <StyledMessage type={AlertType.INFO}>
            <Translate
              id={calendarInfoMessage.key}
              values={{
                b: (chunks: any) => <b>{chunks}</b>,
                a: (linkName: any) => {
                  const link = calendarInfoMessage.links[locale][linkName]
                  return (
                    <a href={link.href} target="_blank">
                      {link.title}
                    </a>
                  )
                },
              }}
            />
          </StyledMessage>
        )}
      </div>
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getAccountId(state),
  account: getAccount(state),
  device: state.env.device,
  locale: getLocale(state),
  calendarInfoMessage: getCalendarInfoMessage(state),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  fetchTimeframes: (params: object) => dispatch(fetchTimeframes(params)),
})

export default connect(mapState, mapDispatch)(ProductDates)
