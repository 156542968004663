import { Translate } from '@jstack/libema-design-system'
import React from 'react'
import styled from 'styled-components'

const Large = styled('span')`
  font-size: 1.5em;
`

const Small = styled('span')`
  font-size: 1.1em;
  vertical-align: top;
`

interface SplitValue {
  round: string
  decimal: string
}

const splitValue = (value: number = 0): SplitValue => {
  const stringValue = value.toFixed(2)
  const split = stringValue.split('.')
  return {
    round: `${split[0]},`,
    decimal: split[1],
  }
}

interface PriceLabelProps {
  prefix?: string
  value: number
  className?: string
}

const PriceLabel: React.FC<PriceLabelProps> = ({ prefix, value, className }) => {
  const price = splitValue(value)
  return (
    <span className={className}>
      {prefix ? <Large>{prefix}</Large> : null}
      {value > 0 && (
        <>
          <Large>{price.round}</Large>
          <Small>{price.decimal}</Small>
        </>
      )}
      {value <= 0 && (
        <Large>
          <Translate id="title.free" />
        </Large>
      )}
    </span>
  )
}

export default PriceLabel
