import { createGlobalStyle } from 'styled-components'

const Theme = createGlobalStyle`
   body {
    background: #ebe8e4;
   }

   header {
       background: white!important;

       & img {
           height: 48px!important;
       }
   }

   .DayPicker {
        padding: 12px 8px;
   }
   .DayPickerNavigation_rightButton__horizontalDefault,
   .DayPickerNavigation_leftButton__horizontalDefault {
        clip-path: polygon(40% 0px, 94% 13%, 100% 60%, 86% 98%, 16% 100%, 0% 60%, 5% 16%) !important;
   }

   form.checkout {
    background: white;
    padding: 12px 0;
   }
 
`

export default Theme
