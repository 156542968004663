import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, IntlShape } from 'react-intl'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { Container, media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { AppState } from '../redux/reducers'
import { OrderState } from '../redux/orderReducer'
import { Account } from '../redux/envReducer'
import Loader from '../components/loaders/Loader'
import { resetState, ResetStateAction } from '../actions/orderActions'
import { ThunkDispatch } from '../redux/types'
import { getOrderStatus, clearSession } from '../api/orderApi'
import { Page } from '../api/pageApi'
import { Helmet } from 'react-helmet'
import analyticsUtils from '../utils/analyticsUtils'
import PaymentUpdateTimeout from '../components/shopping/PaymentUpdateTimeout'
import { H1, Col, Row, RTEContent, Button, Center, SubTitle, Translate } from '@jstack/libema-design-system'
import CardBanner from '../components/common/CardBanner'
import { ACCOUNT_NAMES } from '../data/accounts'

interface OrderConfirmationProps extends RouteComponentProps {
  intl: IntlShape
  account: Account | null
  order: OrderState
  page: Page
  resetOrderState: () => ResetStateAction
}

interface OrderConfirmationState {
  orderNumber?: string
  verifierId?: NodeJS.Timeout
  verificationCancelled: boolean
  // Defines how often we've tried to verify the order
  verificationCount: number
  // Defines is the component is busy checking order status
  verifying: boolean
  isSomerdrome: boolean
  isBrewAtTheZoo: boolean
}

const OrderConfirmationStyled = styled(Row)`
  ${Center} {
    margin: 0;
  }
`

const MobileDownloadButton = styled(Button)`
  display: block;
  margin-bottom: ${({ theme }) => theme.margin.md};
  ${media.tablet`
    display: none;
  `}
`

const DesktopDownloadButton = styled(Button)`
  display: none;
  ${media.tablet`
    display: inline-block;
  `}
`

const SomerImageWrapper = styled(Center)`
  margin-bottom: 1rem !important;

  ${media.tablet`
    margin-top: 2rem;
  `}

  img {
    max-height: 300px;
  }
`

// The amount of seconds between every order status check
const INTERVAL_IN_SECONDS = 2
// The maximum amount of seconds it takes before we show a message to the user that
// verifying the order took to long
const MAX_VERIFICATION_TIME_IN_SECONDS = 50

class OrderConfirmation extends React.Component<OrderConfirmationProps, OrderConfirmationState> {
  constructor(props: OrderConfirmationProps) {
    super(props)
    this.state = {
      verifying: true,
      verificationCancelled: false,
      verificationCount: 0,
      isSomerdrome: false,
      isBrewAtTheZoo: false,
    }
  }

  componentDidMount = () => {
    const verifierId = setInterval(this.verifyOrder, INTERVAL_IN_SECONDS * 1000)
    this.setState({ verifierId })
  }

  isVerificationExpired = () => {
    if (this.state.verificationCount * INTERVAL_IN_SECONDS >= MAX_VERIFICATION_TIME_IN_SECONDS) {
      return true
    }

    return false
  }

  cancelVerification = () => {
    if (typeof this.state.verifierId !== 'undefined') {
      clearInterval(this.state.verifierId)
    }
    this.setState({ verificationCancelled: true, verifying: false })
  }

  verifyOrder = () => {
    const { account, location, order } = this.props
    if (account === null) {
      return
    }

    const queryParams = queryString.parse(location.search)
    if (queryParams.status === 'failed') {
      if (typeof this.state.verifierId !== 'undefined') {
        clearInterval(this.state.verifierId)
      }

      return
    }

    if (typeof queryParams.ref !== 'string') {
      // Sentry.captureException(new Error('No order reference is passed to order confirmation page'))
      return
    }

    if (this.isVerificationExpired()) {
      return this.cancelVerification()
    }

    this.setState((prevState) => ({ verificationCount: prevState.verificationCount + 1 }))

    getOrderStatus(account.id, queryParams.ref).then(({ data }) => {
      if (typeof data.order_number !== 'undefined' && this.state.verifying === true) {
        this.setState({
          orderNumber: data.order_number,
          verifying: false,
          isSomerdrome: data.isSomerdrome,
          isBrewAtTheZoo: data.isBrewAtTheZoo,
        })
        if (typeof this.state.verifierId !== 'undefined') {
          clearInterval(this.state.verifierId)
        }

        if ((queryParams.ref as string) === order.id) {
          analyticsUtils.orderSuccessful(account, data.order_number, data.total, order, data.expositionIds)
        }

        this.props.resetOrderState()

        if (typeof queryParams.ref === 'string') {
          clearSession(queryParams.ref)
        }
      }
    })
  }

  render = () => {
    const { account, intl, location, page } = this.props
    const { verifying, verificationCancelled, isSomerdrome, isBrewAtTheZoo } = this.state
    const queryParams = queryString.parse(location.search)
    const isBeekseBergen = account?.name === ACCOUNT_NAMES.BEEKSEBERGEN

    if (queryParams.status === 'failed') {
      return (
        <Row>
          <Helmet>
            <title>{page.title}</title>
          </Helmet>
          <Col col={12} md={8} mdOffset={2}>
            <Center>
              <H1>
                <Translate id="title.payment_failed" />
              </H1>
              <SubTitle>
                <Translate id="title.payment_failed_subtitle" />
              </SubTitle>
            </Center>
          </Col>
        </Row>
      )
    }

    return (
      <>
        <Container>
          <OrderConfirmationStyled>
            <Helmet>
              <title>{page.title}</title>
            </Helmet>
            <Col col={12} md={8} mdOffset={2}>
              {verificationCancelled && <PaymentUpdateTimeout />}
              {verifying === false && !verificationCancelled && (
                <>
                  {isBeekseBergen && isBrewAtTheZoo && (
                    <SomerImageWrapper>
                      <img src="/beeksebergen/brewatthezoologo.png" />
                    </SomerImageWrapper>
                  )}
                  {isBeekseBergen && !isBrewAtTheZoo && isSomerdrome && process.env.RAZZLE_STAGE !== 'production' && (
                    <SomerImageWrapper>
                      <img src="/beeksebergen/somer.png" />
                    </SomerImageWrapper>
                  )}
                  <H1 centered={true}>
                    {isBeekseBergen && isBrewAtTheZoo ? intl.formatMessage({ id: 'order_confirmation.brewatthezoo.success.title' }) : page.title}
                  </H1>
                  <Center>
                    <MobileDownloadButton as="a" href={`/download/${queryParams.ref}/${page.locale}/tickets.pdf`}>
                      <Translate id="button.download_tickets" />
                    </MobileDownloadButton>
                  </Center>
                  <SubTitle>
                    <RTEContent
                      dangerouslySetInnerHTML={{
                        __html:
                          isBeekseBergen && isBrewAtTheZoo ? intl.formatMessage({ id: 'order_confirmation.brewatthezoo.success.subtitle' }) : page.description,
                      }}
                    />
                  </SubTitle>

                  <Center>
                    <DesktopDownloadButton as="a" href={`/download/${queryParams.ref}/${page.locale}/tickets.pdf`}>
                      <Translate id="button.download_tickets" />
                    </DesktopDownloadButton>
                  </Center>
                </>
              )}
              {verifying === true && (
                <Center>
                  <Loader />
                  <H1>
                    <Translate id="title.checkout_redirect" />
                  </H1>
                  <SubTitle>
                    <Translate id="title.checkout_redirect_subtitle" />
                  </SubTitle>
                </Center>
              )}
            </Col>
          </OrderConfirmationStyled>
        </Container>
        {isBeekseBergen && verifying === false && !verificationCancelled && !isBrewAtTheZoo && (
          <CardBanner
            button="order_confirmation.success.banner.button"
            buttonUrl="order_confirmation.success.banner.button_url"
            title="order_confirmation.success.banner.title"
            subtitle="order_confirmation.success.banner.subtitle"
            imageUrl="/beeksebergen/order-confirmation-banner.png"
          />
        )}
        {isBeekseBergen && verifying === false && !verificationCancelled && isBrewAtTheZoo && (
          <CardBanner
            button="order_confirmation.brewatthezoo.success.banner.button"
            buttonUrl="order_confirmation.brewatthezoo.success.banner.button_url"
            title="order_confirmation.brewatthezoo.success.banner.title"
            subtitle="order_confirmation.brewatthezoo.success.banner.subtitle"
            imageUrl="/beeksebergen/brewatthezoo-order-confirmation-banner.jpg"
          />
        )}
      </>
    )
  }
}

const mapState = (state: AppState) => ({
  account: state.env.account,
  order: state.order,
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  resetOrderState: () => dispatch(resetState()),
})

export default withRouter(connect(mapState, mapDispatch)(injectIntl(OrderConfirmation)))
