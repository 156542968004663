import React from 'react'
import { useOptimizeAB } from '../../../optimize'
import { TimeframeSelectorContainer, TimeframeSelectorBaseProps } from './TimeframeSelectorSomerdrome'
import TimeframeSelectorSomerdromeV2 from './TimeframeSelectorSomerdromeV2'
import Loader from '../../loaders/Loader'

export default (props: TimeframeSelectorBaseProps) => {
  const { isLoading, variant } = useOptimizeAB(process.env.RAZZLE_OPT_EXPERIMENT_ID)
  if (isLoading) {
    return (
      <TimeframeSelectorContainer>
        <Loader />
      </TimeframeSelectorContainer>
    )
  }
  return <TimeframeSelectorSomerdromeV2 {...props} />
}
