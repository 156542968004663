import React from 'react'
import { connect } from 'react-redux'
import { Formik, Field } from 'formik'
import ProductField from './ProductField'
import { Product, Timeframe } from '../../api/productApi'
import { OrderProduct } from '../../api/orderApi'
import { AppState } from '../../redux/reducers'
import { ThunkDispatch } from '../../redux/types'
import { updateOrderProduct } from '../../actions/orderActions'
import { getOrderProducts } from '../../selectors/orderSelectors'
import productUtils, { calculateMaxProducts } from '../../utils/productUtils'
import productTypes from '../../constants/productTypes'
import { Form } from '@jstack/libema-design-system'
import { Exposition } from '../../api/expositionApi'

interface ActivityProductsFormBaseProps {
  activity: Exposition
  products: Product[]
  timeframe: Timeframe
  mainTimeframe: Timeframe
  // Usually the `max` products uses the global order as reference.
  max?: number
}

interface ActivityProductsFormProps extends ActivityProductsFormBaseProps {
  orderProducts: OrderProduct[]
  updateOrderProduct: (item: OrderProduct) => void
}

class ActivityProductsForm extends React.Component<ActivityProductsFormProps> {
  getInitialQuantity = (productId: string) => {
    const cartProduct = this.props.orderProducts.find((op: OrderProduct) => {
      return productUtils.compare(op, productId, this.props.timeframe.id)
    })

    if (cartProduct) {
      return cartProduct.quantity
    }

    return 0
  }

  getInitialValues = () =>
    this.props.products.reduce(
      (values: object, product: Product) => ({
        ...values,
        [product.id]: this.getInitialQuantity(product.id),
      }),
      {}
    )

  getTimeframeMax = () => this.props.timeframe.availability || 20

  getTotalTimeframeTickets = (): number => {
    const { orderProducts, timeframe } = this.props

    return orderProducts
      .filter((product: OrderProduct) => product.period_id === timeframe.id)
      .reduce((total: number, product: OrderProduct) => total + product.quantity, 0)
  }

  getTotalMainTickets = (): number => {
    const { orderProducts, mainTimeframe } = this.props

    return orderProducts
      .filter((product: OrderProduct) => product.period_id === mainTimeframe.id)
      .reduce((total: number, product: OrderProduct) => total + product.quantity, 0)
  }

  getMax = (fieldValue: number): number => {
    const maxTimeframeTickets = this.getTimeframeMax()
    const totalTimeframeTickets = this.getTotalTimeframeTickets()
    const maxTickets = this.props.max || 0
    const totalProducts = this.calculateTotalProductsPerExposition()

    return calculateMaxProducts(fieldValue, maxTimeframeTickets, maxTickets, totalTimeframeTickets, totalProducts)
  }

  calculateTotalProductsPerExposition = (): number => {
    const { orderProducts, activity } = this.props

    return productUtils.totalTicketsByExposition(orderProducts, activity.id)
  }

  render = () => {
    const { timeframe, activity, mainTimeframe } = this.props

    return (
      <Formik
        initialValues={this.getInitialValues()}
        onSubmit={() => {
          // The values are already stored in the redux state on field change.
        }}
      >
        {({ values }) => (
          <Form style={{ overflow: 'hidden' }}>
            {this.props.products.map((product: Product) => (
              <Field
                component={ProductField}
                id={product.id}
                image={product.image}
                key={product.id}
                max={this.getMax(values[product.id])}
                name={product.id}
                onChange={(quantity: number) => {
                  this.props.updateOrderProduct({
                    exposition_id: activity.id,
                    activity_group: activity.name,
                    main_period_id: mainTimeframe.id,
                    description: product.description,
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    type: productTypes.DATE,
                    period_id: timeframe.id,
                    period_from: timeframe.from,
                    period_until: timeframe.until,
                    quantity,
                    voucher: product.voucher,
                  })
                }}
                price={product.price}
                subTitle={product.shortDescription || product.article?.shortDescription}
                title={product.name}
                voucher={product.voucher}
                tooltip={product.description || product.article?.description}
              />
            ))}
          </Form>
        )}
      </Formik>
    )
  }
}

const mapState = (state: AppState, props: ActivityProductsFormBaseProps) => ({
  orderProducts: getOrderProducts(state),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  updateOrderProduct: (item: OrderProduct) => dispatch(updateOrderProduct(item)),
})

export default connect(mapState, mapDispatch)(ActivityProductsForm)
