import React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from '../../redux/types'
import { removeFromCart, updateCart } from '../../actions/orderActions'
import { AppState } from '../../redux/reducers'
import { getTimeframe } from '../../selectors/timeframeSelectors'
import { Timeframe } from '../../api/productApi'
import { Col, Row, DeleteButton, Translate } from '@jstack/libema-design-system'

interface ShoppingCartLineProps {
  canBeRemoved?: boolean
  id: string
  product: string
  timeframe?: Timeframe
  timeframeId?: string
  quantity: number
  voucher?: string
}

interface DispatchProps {
  remove: (productId: string, timeframeId?: string) => void
  update: (lock?: boolean) => void
}

const ShoppingCartLine: React.FC<ShoppingCartLineProps & DispatchProps> = ({
  canBeRemoved,
  id,
  product,
  timeframe,
  timeframeId,
  quantity,
  remove,
  voucher,
  update,
}) => {
  return (
    <Row>
      <Col col={1}>{quantity}</Col>
      <Col col={9}>
        {product}
        {typeof voucher !== 'undefined' && (
          <>
            <br />
            <Translate id="label.voucher_code" />
            &nbsp;
            {voucher}
          </>
        )}
      </Col>
      <Col col={2}>
        {canBeRemoved === true && (
          <DeleteButton
            onClick={() => {
              if (timeframe) {
                remove(id, timeframeId)
                return update(true)
              }

              remove(id)
              return update(true)
            }}
          />
        )}
      </Col>
    </Row>
  )
}

const mapState = (state: AppState, ownProps: ShoppingCartLineProps) => ({
  timeframe: getTimeframe(state, ownProps.timeframeId),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  remove: (productId: string, timeframeId?: string) => dispatch(removeFromCart(productId, timeframeId)),
  update: (lock?: boolean) => dispatch(updateCart(lock)),
})

export default connect(mapState, mapDispatch)(ShoppingCartLine)
