import App from './pages/App'
import DynamicPage from './pages/DynamicPage'
import NotFound from './pages/NotFound'
import Vouchers from './pages/Vouchers'
import CookiePage from './pages/CookiePage'

export default [
  {
    path: '/',
    exact: true,
    component: NotFound,
  },
  {
    path: '/:slug(voucher|en\/voucher|fr\/voucher|de\/voucher)',
    exact: true,
    component: Vouchers,
  },
  {
    path: '/:slug(checkout|en\/checkout|fr\/checkout|de\/checkout)',
    exact: true,
    component: App,
  },
  {
    path: '/cookies',
    exact: true,
    component: CookiePage
  },
  {
    // When the page data is not found, it returns a 404 statusCode which 
    // automatically triggers the NotFound route below
    path: '/*',
    component: DynamicPage,
  },
  {
    component: NotFound
  }
]
