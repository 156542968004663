import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { media } from 'styled-bootstrap-grid'
import qs from 'query-string'
import { AppState } from '../../redux/reducers'
import { Account } from '../../redux/envReducer'
import DierentuinTicketsLogo from '../../../public/img/dierentuintickets-collaboration.svg'
import { Container, Col, Row, Translate } from '@jstack/libema-design-system'
import { getLocale } from '../../selectors/envSelectors'
import { HeaderAlert } from './'

const HeaderWrapper = styled.header`
  background-color: ${({ theme }) => theme.color.neutralMedium};
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const Logo = styled.img`
  margin: ${({ theme }) => `${theme.margin.md} 0`};
  height: 30px;
`

const LeftCol = styled(Col)`
  text-align: center;
  ${media.tablet`
    text-align: left;
  `}
`

const RightCol = styled(Col)`
  text-align: center;
  ${media.tablet`
    text-align: right;
  `}
`

const EnvBanner = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  padding: ${({ theme }) => theme.padding.md};
  color: #fff;
`

const ReferalLogo = styled(DierentuinTicketsLogo)`
  height: 60px;
`

const ReferalLink = styled.a`
  display: block;
  font-size: 0.8rem;
  margin-top: ${({ theme }) => theme.margin.md};
  margin-right: ${({ theme }) => theme.margin.md};
  color: #fff;
  &:hover {
    color: #fff;
  }
  &:visited {
    color: #fff;
  }

  ${media.tablet`
    margin-top: 0;
    display: inline-block;
  `}
`

interface HeaderProps extends RouteComponentProps {
  account: Account | null
  locale: string
}

const getDTTReferer = (location: HeaderProps['location']): string => {
  const searchParams = qs.parse(location.search)
  if (typeof searchParams.referral !== 'undefined') {
    return `https://www.dierentuintickets.nl/${searchParams.referral}`
  }

  return ''
}

const Header: React.FC<HeaderProps> = ({ account, locale, location }) => {
  const referral = getDTTReferer(location)

  return (
    <HeaderWrapper>
      {process.env.RAZZLE_STAGE !== 'production' && <EnvBanner>You're looking at a test environment</EnvBanner>}
      {account?.headerAlert && <HeaderAlert alert={account.headerAlert} locale={locale} />}
      <Container>
        <Row>
          <LeftCol md={8}>
            {referral !== '' && (
              <ReferalLink href={referral} target="_blank">
                <Translate id="button.back_to_referral" values={{ referral: 'dierentuintickets.nl' }} />
              </ReferalLink>
            )}
            <Logo src={account && account.theme ? account.theme.logo : ''} />
          </LeftCol>
          {referral !== '' && (
            <RightCol md={4}>
              <ReferalLogo />
            </RightCol>
          )}
          {/* <RightCol col={4}>
            <LanguageSelector />
          </RightCol> */}
        </Row>
      </Container>
    </HeaderWrapper>
  )
}

const mapState = (state: AppState) => ({
  account: state.env.account,
  locale: getLocale(state),
})

export default withRouter(connect(mapState)(Header))
