import { SET_PRODUCTS } from '../actions/productActions';
import { Product } from '../api/productApi';

interface SetProductsAction {
  type: typeof SET_PRODUCTS;
  products: object;
}

export interface ProductState {
  [id: string]: Product;
}

export const initialState: ProductState = {};

export default (state: ProductState = initialState, action: SetProductsAction) => {
  switch (action.type) {
    case SET_PRODUCTS: {
      return {
        ...action.products
      }
    }
    default: {
      return state;
    }
  }
};
