import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import * as Sentry from '@sentry/browser'
import { OptionGroup as BaseOptionGroup, Radio, Translate } from '@jstack/libema-design-system'
import { AppState } from '../../redux/reducers'
import { getTimeframes, Timeframe } from '../../api/productApi'
import productUtils from '../../utils/productUtils'
import TimeframesUnavailable from './TimeframesUnavailable'
import Loader from '../loaders/Loader'
import { getAccountId } from '../../selectors/envSelectors'

interface TimeframeSelectorBaseProps {
  date: string
  expositionId: string
  timeFrameAlmostSoldOutThreshold: number
  onChange: (timeframe?: Timeframe) => void
  // The ID of the timeframe
  value?: string
}

interface TimeframeSelectorProps extends TimeframeSelectorBaseProps {
  accountId?: string | null
  locale: string
  selectedByDefault?: boolean
}

// This bottom-margin can be removed as soon as @jstack/libema-design-system@0.1.18 is released
const OptionGroup = styled(BaseOptionGroup)`
  margin-bottom: ${({ theme }) => theme.margin.sm};
`

const Options = styled.div`
  flex-grow: 1;
  margin-bottom: ${({ theme }) => theme.margin.md};
`

interface LabelProps {
  disabled?: boolean
}

const Label = styled.label<LabelProps>`
  text-decoration: ${({ disabled }) => (disabled === true ? 'line-through' : 'none')};
`

const TimeframeSelector: FC<TimeframeSelectorProps> = ({
  accountId,
  locale,
  date,
  expositionId,
  timeFrameAlmostSoldOutThreshold,
  onChange,
  value,
  selectedByDefault,
}) => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const [timeframes, setTimeframes] = useState<Timeframe[]>([])

  useEffect(() => {
    setLoading(true)
    const from = moment(date).format('YYYY-MM-DD[T]HH:mm:ss')
    const until = moment(date).add(1, 'day').format('YYYY-MM-DD')

    if (accountId) {
      getTimeframes(accountId, { locale, exposition_id: expositionId, from, until })
        .then((res) => {
          setTimeframes(res.data)

          const first = res.data.find((timeframe) => timeframe.availability > 0)

          // Whenever the `date` changes, we automatically set the first available timeframe
          if (first && selectedByDefault) {
            onChange(first)
          } else {
            onChange(undefined)
          }
        })
        .catch((e: Error) => Sentry.captureException(e))
        .then(() => setLoading(false))
    }
  }, [date])

  const firstAvailable = timeframes.find((timeframe) => timeframe.availability > 0)

  if (!isLoading && (timeframes.length === 0 || !firstAvailable)) {
    return <TimeframesUnavailable expositionId={expositionId} />
  }

  return (
    <>
      {isLoading && <Loader />}
      <Options>
        {timeframes.map((timeframe) => (
          <OptionGroup key={timeframe.id} inline={true}>
            <Radio
              key={timeframe.id}
              id={`timeframe-${timeframe.id}`}
              checked={value === timeframe.id}
              disabled={timeframe.availability < 1}
              onChange={() => onChange(timeframe)}
              value={timeframe.id}
            />
            <Label htmlFor={`timeframe-${timeframe.id}`} disabled={timeframe.availability < 1}>
              <Translate
                id="label.timeframe_from_util"
                values={{
                  from: moment(timeframe.from).format('H:mm'),
                  until: moment(timeframe.until).format('H:mm'),
                }}
              />
            </Label>
            {productUtils.isAlmostSoldOut(timeframe, timeFrameAlmostSoldOutThreshold) && (
              <>
                &nbsp;(
                <Translate id="label.almost_sold_out" />)
              </>
            )}
            {productUtils.isSoldOut(timeframe) && (
              <>
                &nbsp;(
                <Translate id="label.sold_out" />)
              </>
            )}
          </OptionGroup>
        ))}
      </Options>
    </>
  )
}

const mapStateToProps = (state: AppState, props: TimeframeSelectorBaseProps) => ({
  locale: state.env.locale,
  accountId: getAccountId(state),
})

export default connect(mapStateToProps)(TimeframeSelector)
