import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Translate } from '@jstack/libema-design-system'

interface ShoppingCartTitleProps {
  identifier: string
  from?: string
  until?: string
  label?: string
}

const ShoppingCartTitleContainer = styled.div`
  font-weight: bold;
  margin: ${({ theme }) => theme.margin.sm} 0;
`

const ShoppingCartTitle: React.FC<ShoppingCartTitleProps> = ({ identifier, from, until, label }) => (
  <ShoppingCartTitleContainer>
    {label}
    {!label && from && until && (
      `${moment(from).format('D MMMM YYYY, H:mm')} - ${moment(until).format('H:mm')}`
    )}
    {identifier === 'vouchers' && (
      <Translate id="title.voucher_products" />
    )}
    {identifier === 'other' && (
      <Translate id="title.additional_products" />
    )}
  </ShoppingCartTitleContainer>
)

export default ShoppingCartTitle
