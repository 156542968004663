import React from 'react'

const MAX_RETRY = 3000 // stop after fetching variant after 3 seconds

declare const window: Window &
  typeof globalThis & {
    dataLayer: any
    google_optimize: any
  }

export const useOptimizeAB = (experimentId: string) => {
  const [isLoading, setLoading] = React.useState(false)
  const [variant, setVariant] = React.useState()

  if (!experimentId) {
    return { isLoading, undefined }
  }

  React.useEffect(() => {
    setLoading(true)
    const getVariant = async () => {
      if (window.dataLayer) {
        await window.dataLayer.push({ event: 'optimize.activate' })
      }
      const interval = setInterval(() => {
        if (window.google_optimize !== undefined) {
          const variantId = window.google_optimize.get(experimentId)
          if (typeof variantId !== 'undefined') {
            setVariant(variantId)
            setLoading(false)
          }
          clearInterval(interval)
        }
        setTimeout(() => {
          setLoading(false)
          clearInterval(interval)
        },         MAX_RETRY)
      },                           100)
    }
    getVariant()
  },              [])
  return { isLoading, variant }
}
