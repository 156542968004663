import React, { Component } from 'react'
import styled from 'styled-components'
import queryString from 'query-string'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Container, Translate } from '@jstack/libema-design-system'
import RootProvider from '../components/providers/RootProvider'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import AgeConsent from '../components/common/AgeConsent'
import { CustomTheme, Theme } from '../theme'
import { media } from 'styled-bootstrap-grid'

import 'react-dates/initialize'
import 'moment/locale/nl'
import 'moment/locale/fr'
import 'moment/locale/de'
import CardBanner from '../components/common/CardBanner'
import { ACCOUNT_NAMES } from '../data/accounts'

interface BaseProps extends RouteComponentProps {
  isIE: boolean
  theme: CustomTheme
  accountName?: string
  isTicketShop?: boolean
  isBrewAtZoo?: boolean
}

interface BaseState {
  ageAllowed: boolean | null
}

type GlobalContainerProps = {
  theme: Theme
  isBrewAtZoo?: boolean
}

const Main = styled.main`
  flex: 1;

  ${media.desktop`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`}
`

const StyledGlobalContainer = styled.div<GlobalContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, isBrewAtZoo }) => (isBrewAtZoo ? theme.backgroundColor.content : 'transparent')};
  min-height: 100vh;
`

class Base extends Component<BaseProps, BaseState> {
  constructor(props: BaseProps) {
    super(props)
    this.state = {
      ageAllowed: null,
    }
  }
  static async getInitialProps(props: BaseProps) {
    return {
      isIE: props.isIE,
      theme: props.theme,
      isTicketShop: props.isTicketShop,
      isBrewAtZoo: props.isBrewAtZoo,
      accountName: props.accountName,
    }
  }

  setAgeAllowed = (value: boolean) => this.setState({ ageAllowed: value })

  render = () => {
    const { children, theme, isIE, isTicketShop, isBrewAtZoo, location, accountName } = this.props
    const isBrewAtZooFull = isTicketShop && isBrewAtZoo && !this.state.ageAllowed
    const isBeekseBergen = accountName === ACCOUNT_NAMES.BEEKSEBERGEN
    const { order, status } = queryString.parse(location?.search)
    const isConfirmationSuccessPage = location.pathname === '/checkout/redirect' && status === 'success' && order

    let warning = <></>
    if (isIE) {
      warning = (
        <p className="oldbrowser">
          <Translate
            id="warning.old_browser"
            values={{
              strong: (chunks: any) => <strong>{chunks}</strong>,
              a: (chunks: any) => <a href="https://browsehappy.com/">{chunks}</a>,
            }}
          />
        </p>
      )
    }

    return (
      <RootProvider theme={theme}>
        {warning}
        <StyledGlobalContainer isBrewAtZoo={isBrewAtZooFull}>
          <Header />
          <Main>
            {isBrewAtZooFull ? (
              <AgeConsent
                setAgeAllowed={this.setAgeAllowed}
                imageMobile="/beeksebergen/brew@thezoo_mobile.jpg"
                imageDesktop="/beeksebergen/brew@thezoo_desktop.jpg"
              />
            ) : !isConfirmationSuccessPage ? (
              <Container>{children}</Container>
            ) : (
              children
            )}
          </Main>
          <Footer />
        </StyledGlobalContainer>
      </RootProvider>
    )
  }
}

export default withRouter(Base)
