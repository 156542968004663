import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { H3, Center, Translate, deviceUtils } from '@jstack/libema-design-system'

const TitleWrapper = styled(Center)`
  font-size: 2rem;
  font-weight: bold;
  margin: ${({ theme }) => theme.margin.lg} 0;
`

const Date = styled.span`
  display: block;
  border: 3px solid ${({ theme }) => theme.color.primary};
  padding: 0 ${({ theme }) => theme.padding.sm};
  font-size: 1.5rem;

  @media ${deviceUtils.DEVICE_LG_UP} {
    font-size: 2rem;
    display: inline-block;
    margin-left: ${({ theme }) => theme.margin.md};
  }
`

interface ProductDateTitleProps {
  from: string
  until: string
}

export const ProductDateTitle: React.FC<ProductDateTitleProps> = ({ from, until }) => (
  <H3>
    <Translate id="title.selected_date" />
    &nbsp;
    {`${moment(from).format('D MMMM YYYY')}`}
    {/* <Date>{`${moment(from).format('D MMMM YYYY, H:mm')} - ${moment(until).format('H:mm')}`}</Date> */}
  </H3>
)

interface ActivityDateTitleProps {
  date: string
  useArrangements?: boolean
}

export const ActivityDateTitle: React.FC<ActivityDateTitleProps> = ({ date, useArrangements }) => (
  <H3>
    {useArrangements ? <Translate id="tickets.arrangements.title" /> : <Translate id="tickets.activities.title" />}
    &nbsp;
    {moment(date).format('D MMMM YYYY')}
  </H3>
)
