import styled, { keyframes } from 'styled-components';
import LoaderIcon from '../../../public/img/loader.svg';

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export default styled(LoaderIcon)<{ size?: 'lg' }>`
  width: ${({ size }) => size === 'lg' ? '3rem' : '1rem'};
  -webkit-animation: ${rotate} 2s linear infinite;
  animation: ${rotate} 2s linear infinite;
`;
