import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Formik, Field } from 'formik'
import ProductField from './ProductField'
import { Product, Timeframe } from '../../api/productApi'
import { OrderProduct } from '../../api/orderApi'
import { AppState } from '../../redux/reducers'
import { ThunkDispatch } from '../../redux/types'
import { updateOrderProduct } from '../../actions/orderActions'
import { fetchProducts, FetchProductsParams } from '../../actions/productActions'
import { getProductsByType } from '../../selectors/productSelectors'
import { getTimeframes, getTimeframe, hasTimeframes } from '../../selectors/timeframeSelectors'
import { getOrderProducts, getTotalTimeframeTickets } from '../../selectors/orderSelectors'
import ProductsFormSkelecton from './ProductsFormSkeleton'
import { TimeframesState } from '../../redux/timeframeReducer'
import productUtils, { calculateMaxProducts } from '../../utils/productUtils'
import productTypes from '../../constants/productTypes'
import { getAccount } from '../../selectors/envSelectors'
import { Account } from '../../redux/envReducer'
import { Col, Row, Form, Alert, AlertType, Translate } from '@jstack/libema-design-system'
import TimeframesUnavailable from './TimeframesUnavailable'
import { TimeframeSelectorContainer } from './TimeframeSelectorSomerdrome/TimeframeSelectorSomerdrome'

interface ProductsFormBaseProps {
  expositionId?: string
  max?: number
  // Usually the `max` products uses the global order as reference.
  // When setting `maxWithinForm` to true, it will only calculate the total products within the form
  maxWithinForm?: boolean
  products?: Product[]
  timeframeId?: string
  type: string
}

interface ProductsFormProps extends ProductsFormBaseProps {
  account: Account | null
  getProducts: (params: FetchProductsParams) => Promise<void>
  orderProducts: OrderProduct[]
  timeframes: TimeframesState
  products: Product[]
  timeframe?: Timeframe
  totalTimeframeTickets: number
  updateOrderProduct: (item: OrderProduct) => void
}

interface ProductsFromState {
  error: boolean
  isPending: boolean
}

interface ProductsFormValues {
  [id: string]: number
}

class ProductsForm extends React.Component<ProductsFormProps, ProductsFromState> {
  state = {
    error: false,
    isPending: false,
  }

  componentDidMount = () => {
    this.fetchProducts()
  }

  componentDidUpdate = (prevProps: ProductsFormProps) => {
    if (prevProps.timeframe !== this.props.timeframe) {
      this.fetchProducts()
    }
  }

  fetchProducts = () => {
    if (![productTypes.DATE].includes(this.props.type)) {
      return
    }

    // Avoid fetching standard products if they're already available in the state
    // E.g. because of server-side rendering
    if ([productTypes.STANDARD, productTypes.VOUCHER].includes(this.props.type) && this.props.products.length > 0) {
      return
    }

    this.setState({ isPending: true })

    const params: FetchProductsParams = {}
    if (this.props.timeframe && this.props.expositionId) {
      params.date = this.props.timeframe.from
      params.exposition_id = this.props.expositionId
    }

    this.props
      .getProducts(params)
      .then(() => {
        if (process.env.RAZZLE_STAGE === 'testing' && this.props?.timeframe?.from === '2020-12-24T09:30:00') {
          throw new Error('Manual error to test error handling')
        }

        return this.setState({ isPending: false })
      })
      .catch(() => {
        this.setState({
          error: true,
          isPending: false,
        })
      })
  }

  getInitialQuantity = (productId: string) => {
    const cartProduct = this.props.orderProducts.find((op: OrderProduct) => {
      return productUtils.compare(op, productId, this.props.timeframeId)
    })

    if (cartProduct) {
      return cartProduct.quantity
    }

    return 0
  }

  getInitialValues = (): ProductsFormValues =>
    this.props.products.reduce(
      (values: object, product: Product) => ({
        ...values,
        [product.id]: this.getInitialQuantity(product.id),
      }),
      {}
    )

  getTimeframeMax = (): number => {
    const { timeframe } = this.props

    if (!timeframe?.availability) {
      return 20
    }

    if (timeframe?.controlType === 'group' && timeframe.maximum && timeframe.maximumPerGroup) {
      return timeframe.maximum * timeframe.maximumPerGroup
    }

    return timeframe?.availability
  }

  getMax = (maxQuantity: number, fieldValue: number): number => {
    const { max = 0, maxWithinForm, totalTimeframeTickets } = this.props

    const maxTimeframeTickets = this.getTimeframeMax()
    let totalProducts = this.calculateTotalProductsPerExposition()

    if (maxWithinForm) {
      totalProducts = this.calculateTotalProductsInForm()
    }

    return calculateMaxProducts(fieldValue, maxTimeframeTickets, max, totalTimeframeTickets, totalProducts, maxQuantity)
  }

  calculateTotalProductsPerExposition = (): number => {
    const { orderProducts, expositionId } = this.props

    if (!expositionId) {
      return 0
    }

    return productUtils.totalTicketsByExposition(orderProducts, expositionId)
  }

  /**
   * For products non-related to exposition/timeframes
   */
  calculateTotalProductsInForm = (): number => {
    const { products, orderProducts } = this.props

    return products
      .map((product) => {
        const orderProduct = orderProducts.find((p) => p.id === product.id)
        if (typeof orderProduct === 'undefined') {
          return 0
        }
        return orderProduct.quantity
      })
      .reduce((total: number, quantity: number) => total + quantity, 0)
  }

  render = () => {
    const products = _.sortBy(this.props.products, ['sortOrder'])

    if (this.state.isPending) {
      return <ProductsFormSkelecton />
    }

    return (
      <Formik
        initialValues={this.getInitialValues()}
        onSubmit={() => {
          // The values are already stored in the redux state on field change.
        }}
      >
        {({ values }) => (
          <Form>
            <Row>
              <Col col={12}>
                {this.state.error === true && (
                  <Alert type={AlertType.ERROR}>
                    <Translate id="error.products_fetch_failed" values={{ phoneNumber: this.props.account?.phone_number }} />
                  </Alert>
                )}
                {products.map((product: Product) => (
                  <Field
                    key={product.id}
                    component={ProductField}
                    id={product.id}
                    image={product.image}
                    max={this.getMax(product.max_quantity, values[product.id])}
                    min={product.min_quantity}
                    maxQuantity={product.max_quantity}
                    name={product.id}
                    onChange={(quantity: number) => {
                      this.props.updateOrderProduct({
                        exposition_id: this.props.expositionId,
                        description: product.description,
                        id: product.id,
                        name: product.name,
                        price: product.price,
                        type: this.props.type,
                        period_id: this.props.timeframeId,
                        period_from: this.props.timeframe?.from,
                        period_until: this.props.timeframe?.until,
                        quantity,
                        voucher: product.voucher,
                      })
                    }}
                    price={product.price}
                    from_price={product.from_price}
                    subTitle={product.shortDescription || product.article?.shortDescription}
                    title={product.name}
                    voucher={product.voucher}
                    tooltip={product.description || product.article?.description}
                  />
                ))}
                {this.props.totalTimeframeTickets >= this.getTimeframeMax() && (
                  <Alert type={AlertType.ERROR}>
                    <Translate id="error.max_timeframe_reached" />
                  </Alert>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    )
  }
}

const mapState = (state: AppState, props: ProductsFormBaseProps) => ({
  account: getAccount(state),
  products: props.products || getProductsByType(state, props.type, props.timeframeId),
  orderProducts: getOrderProducts(state),
  timeframe: getTimeframe(state, props.timeframeId),
  timeframes: getTimeframes(state),
  totalTimeframeTickets: getTotalTimeframeTickets(state, props.timeframeId),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  getProducts: (params: FetchProductsParams) => dispatch(fetchProducts(params)),
  updateOrderProduct: (item: OrderProduct) => dispatch(updateOrderProduct(item)),
})

export default connect(mapState, mapDispatch)(ProductsForm)
