import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled, { css } from 'styled-components'
import * as Sentry from '@sentry/browser'
import { Button, deviceUtils, OptionGroup as BaseOptionGroup, Radio, Translate } from '@jstack/libema-design-system'
import { AppState } from '../../../redux/reducers'
import { getAvailableTimeframesForDate } from '../../../selectors/timeframeSelectors'
import productUtils from '../../../utils/productUtils'
import { Timeframe } from '../../../api/productApi'
import TimeframesUnavailable from '../TimeframesUnavailable'
import { ThunkDispatch } from '../../../redux/types'
import { fetchTimeframes as fetchTimeframesRequest } from '../../../actions/productActions'
import Loader from '../../loaders/Loader'

import someravond from './someravond.png'
import somerdag from './somerdag.png'

export interface TimeframeSelectorBaseProps {
  date: moment.Moment
  expositionId: string
  timeFrameAlmostSoldOutThreshold: number
  onButtonClick: () => void
  onChange: (timeframe?: Timeframe) => void
  // The ID of the timeframe
  value?: string
}

interface TimeframeSelectorProps extends TimeframeSelectorBaseProps {
  fetchTimeframes: (params: object) => Promise<Timeframe[]>
  timeframes: Timeframe[]
}

const ImageBlock = styled.div`
  text-align: center;
  margin-bottom: 16px;

  img {
    height: 69px;
  }

  @media ${deviceUtils.DEVICE_LG_DOWN} and ${deviceUtils.DEVICE_MD_UP} {
    img {
      max-width: 100%;
      height: auto;
    }
  }
`

// This bottom-margin can be removed as soon as @jstack/libema-design-system@0.1.18 is released
const OptionGroup = styled(BaseOptionGroup)`
  margin-bottom: ${({ theme }) => theme.margin.sm};
`

export const TimeframeSelectorContainer = styled.div`
  background-color: ${({ theme }) => theme.color.neutralLight};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: ${({ theme }) => theme.padding.md};
  padding-bottom: ${({ theme }) => theme.padding.md};
`

const Description = styled.p`
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const Options = styled.div`
  flex-grow: 1;
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const TabGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-bottom: 1rem;

  @media ${deviceUtils.DEVICE_SM_DOWN}, ${deviceUtils.DEVICE_LG_DOWN} and ${deviceUtils.DEVICE_MD_UP} {
    grid-template-columns: 1fr;
  }
`

const Tab = styled(Button)<{ active: boolean; time: 'day' | 'evening' }>`
  font-weight: bold;
  background-color: ${({ theme, active }) => (active ? theme.color.neutralMedium : 'transparent')};
  border: 2px solid ${({ theme }) => theme.color.neutralMedium};
  color: ${({ theme, active }) => (active ? 'white' : theme.color.neutralMedium)};
  padding: 8px 4px 4px;

  &:hover {
    background-color: ${({ theme }) => theme.color.neutralMedium};
  }

  span:nth-child(n + 2) {
    font-size: 0.85rem;
    font-weight: normal;
    vertical-align: top;
  }

  ${({ active, time }) =>
    time === 'evening' &&
    css`
      background-color: ${active ? '#C08F16' : 'transparent'};
      border: 2px solid #c08f16;
      color: ${active ? 'white' : '#C08F16'};
      &:hover {
        background-color: #c08f16;
      }
    `}
`

interface LabelProps {
  disabled?: boolean
}

const Label = styled.label<LabelProps>`
  text-decoration: ${({ disabled }) => (disabled === true ? 'line-through' : 'none')};
`

const TimeframeSelectorSomerdrome: React.FC<TimeframeSelectorProps> = ({
  date,
  expositionId,
  fetchTimeframes,
  timeframes,
  timeFrameAlmostSoldOutThreshold,
  onButtonClick,
  onChange,
  value,
}) => {
  const [isLoading, setLoading] = React.useState<boolean>(true)
  const [isTimeframeDay, setIsTimeframeDay] = React.useState<boolean>(false)
  const [timeframesDay, setTimeframesDay] = React.useState<Timeframe[]>([])
  const [timeframesEvening, setTimeframesEvening] = React.useState<Timeframe[]>([])
  const selectedTimeframes = isTimeframeDay ? timeframesDay : timeframesEvening
  const setIsTimeframeDayTrue = () => setIsTimeframeDay(true)
  const setIsTimeframeDayFalse = () => setIsTimeframeDay(false)

  let somerdromeDetails = {
    image: someravond,
    descriptionKey: 'somerdrome.evening.timeframes_subtitle',
  }

  if (isTimeframeDay) {
    somerdromeDetails = {
      image: somerdag,
      descriptionKey: 'somerdrome.day.timeframes_subtitle',
    }
  }

  React.useEffect(() => {
    setLoading(true)
    const today = moment().add(5, 'minutes')
    const isToday = date.isSame(today, 'date')
    const from = isToday ? today.format('YYYY-MM-DD[T]HH:mm:ss') : date.format('YYYY-MM-DD')
    const until = date.clone().add(1, 'day').format('YYYY-MM-DD')

    fetchTimeframes({ exposition_id: expositionId, from, until })
      .then((response: Timeframe[]) => {
        setLoading(false)

        if (response.length) {
          const dayTimeframes: Timeframe[] = []
          const eveningTimeframes: Timeframe[] = []
          response.forEach((timeframe) => {
            const hour = moment(timeframe.from).hour()
            if (hour < 15) {
              dayTimeframes.push(timeframe)
            } else {
              eveningTimeframes.push(timeframe)
            }
          })
          setTimeframesDay(dayTimeframes)
          setTimeframesEvening(eveningTimeframes)

          let first

          if (eveningTimeframes.length) {
            setIsTimeframeDay(false)
            first = eveningTimeframes.find((timeframe) => timeframe.availability > 0)
          } else {
            setIsTimeframeDay(true)
            first = dayTimeframes.find((timeframe) => timeframe.availability > 0)
          }

          // Whenever the `date` changes, we automatically set the first available timeframe
          if (first) {
            onChange(first)
          } else {
            onChange(undefined)
          }
        }
      })
      .catch((e: Error) => {
        Sentry.captureException(e)
      })
      .then(() => {
        setLoading(false)
      })
  },              [date])

  const firstAvailable = selectedTimeframes.find((timeframe) => timeframe.availability > 0)

  if (!isLoading && (timeframes.length < 1 || !firstAvailable)) {
    return (
      <TimeframeSelectorContainer>
        <TimeframesUnavailable />
      </TimeframeSelectorContainer>
    )
  }

  if (isLoading) {
    return (
      <TimeframeSelectorContainer>
        <Loader />
      </TimeframeSelectorContainer>
    )
  }

  const showTabs = timeframesDay.length && timeframesEvening.length

  return (
    <TimeframeSelectorContainer>
      <ImageBlock>
        <img src={somerdromeDetails.image} />
      </ImageBlock>
      <Description>
        <Translate
          id={somerdromeDetails.descriptionKey}
          values={{
            strong: (chunks: any) => <strong>{chunks}</strong>,
          }}
        />
      </Description>
      {showTabs ? (
        <TabGroup>
          <Tab time="day" active={isTimeframeDay} onClick={setIsTimeframeDayTrue}>
            <Translate id="tickets.timeframe.tabs_day" />
            <br />
            <Translate id="tickets.timeframe.tabs_day.subtitle" />
          </Tab>
          <Tab time="evening" active={!isTimeframeDay} onClick={setIsTimeframeDayFalse}>
            <Translate id="tickets.timeframe.tabs_evening" />
            <br />
            <Translate id="tickets.timeframe.tabs_evening.subtitle" />
          </Tab>
        </TabGroup>
      ) : null}
      <Options>
        {selectedTimeframes.map((timeframe) => (
          <OptionGroup key={timeframe.id} inline={true}>
            <Radio
              key={timeframe.id}
              id={`timeframe-${timeframe.id}`}
              checked={value === timeframe.id}
              disabled={timeframe.availability < 1}
              onChange={() => onChange(timeframe)}
              value={timeframe.id}
            />
            <Label htmlFor={`timeframe-${timeframe.id}`} disabled={timeframe.availability < 1}>
              <Translate
                id="label.timeframe_from_util"
                values={{
                  from: moment(timeframe.from).format('H:mm'),
                  until: moment(timeframe.until).format('H:mm'),
                }}
              />
            </Label>
            {productUtils.isAlmostSoldOut(timeframe, timeFrameAlmostSoldOutThreshold) && (
              <>
                &nbsp;(
                <Translate id="label.almost_sold_out" />)
              </>
            )}
            {productUtils.isSoldOut(timeframe) && (
              <>
                &nbsp;(
                <Translate id="label.sold_out" />)
              </>
            )}
          </OptionGroup>
        ))}
      </Options>
      <Button fullWidth={true} onClick={onButtonClick}>
        <Translate id="button.to_products" />
      </Button>
    </TimeframeSelectorContainer>
  )
}

const mapState = (state: AppState, props: TimeframeSelectorBaseProps) => ({
  timeframes: getAvailableTimeframesForDate(state, props.date.format('YYYY-MM-DD')),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  fetchTimeframes: (params: object) => dispatch(fetchTimeframesRequest(params)),
})

export default connect(mapState, mapDispatch)(TimeframeSelectorSomerdrome)
