import { deviceUtils, H3 } from '@jstack/libema-design-system'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.neutralLight};
  margin-bottom: ${({ theme }) => theme.margin.sm};
  padding: ${({ theme }) => `${theme.padding.md} ${theme.padding.lg}`};

  @media ${deviceUtils.DEVICE_MD_DOWN} {
    padding: 20px;
  }
`

export const Title = styled(H3)`
  margin-bottom: 0.5rem;
  word-wrap: break-word;
  white-space: pre-wrap
  word-break: break-word;
`

export const SubTitle = styled.span`
  display: block;
  margin-bottom: ${({ theme }) => theme.margin.md};
`
