import { AxiosResponse } from 'axios'
import { ThunkResult } from '../redux/types'
import { omit } from 'lodash';
import { getAccountId, getLocale } from '../selectors/envSelectors'
import { getExposition, Exposition } from '../api/expositionApi'

export const SET_EXPOSITION = 'SET_EXPOSITION'

export const setExposition = (exposition: object) => ({
  type: SET_EXPOSITION,
  exposition,
})

export const fetchExposition = (expositionId: string): ThunkResult<Promise<Exposition>> => (dispatch, getState) => {
  const state = getState()
  const accountId = getAccountId(state) || ''
  const locale = getLocale(state)

  return getExposition(accountId, expositionId, locale)
    .then((res: AxiosResponse<Exposition>) => {
      const normalized = omit(res.data, 'optional_products')
      dispatch(setExposition(normalized))
      return res.data
    })
}