import { getProducts, getTimeframes, Timeframe, getExpositionProducts } from '../api/productApi'
import { AxiosResponse } from 'axios'
import { ThunkResult } from '../redux/types'
import { normalize } from '../utils/normalizeUtils'
import { getAccountId, getLocale } from '../selectors/envSelectors'
import { getExposition, Exposition } from '../api/expositionApi'

export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_TIMEFRAMES = 'SET_TIMEFRAMES'
export const CLEAR_TIMEFRAMES = 'CLEAR_TIMEFRAMES'

export const setProducts = (products: object) => ({
  type: SET_PRODUCTS,
  products,
})

export const setTimeframes = (timeframes: object) => ({
  type: SET_TIMEFRAMES,
  timeframes,
})

export const clearTimeframes = () => ({
  type: CLEAR_TIMEFRAMES,
})

export interface FetchProductsParams {
  date?: string
  exposition_id?: string
}

export const fetchProducts =
  (params: FetchProductsParams): ThunkResult<Promise<void>> =>
  (dispatch, getState) => {
    const state = getState()
    const locale = state.env.locale
    const accountId = getAccountId(state) || ''

    dispatch(setProducts({}))

    if (typeof params.exposition_id !== 'undefined') {
      const { exposition_id, ...restParams } = params
      return getExpositionProducts(accountId, exposition_id, { locale, ...restParams }).then((res: AxiosResponse) => {
        const normalized = normalize(res.data.items, 'id')

        dispatch(setProducts(normalized))
      })
    }

    return getProducts(accountId, { locale, ...params }).then((res: AxiosResponse) => {
      const normalized = normalize(res.data.items, 'id')

      dispatch(setProducts(normalized))
    })
  }

export const fetchTimeframes =
  (params: object): ThunkResult<Promise<Timeframe[]>> =>
  (dispatch, getState) => {
    const state = getState()
    const locale = state.env.locale
    const accountId = getAccountId(state) || ''

    return getTimeframes(accountId, { locale, ...params })
      .then((res: AxiosResponse) => {
        // Filter out unavailable time frames. Maximum has been set to
        // 0 intentionally.
        const timeFrames = res.data.filter((timeFrame: Timeframe) => {
          if (timeFrame.maximum != null && timeFrame.maximum === 0) {
            return false
          }
          return true
        })

        const normalized = normalize(timeFrames, 'id')

        dispatch(setTimeframes(normalized))
        return typeof res.data === 'object' ? res.data : []
      })
      .catch(() => {
        dispatch(clearTimeframes())
        dispatch(setProducts({}))
      })
  }

export const fetchExposition =
  (expositionId: string): ThunkResult<Promise<Exposition>> =>
  (dispatch, getState) => {
    const state = getState()
    const accountId = getAccountId(state) || ''
    const locale = getLocale(state)

    return getExposition(accountId, expositionId, locale).then((res: AxiosResponse<Exposition>) => {
      const normalized = normalize(res.data.optional_products, 'id')
      dispatch(setProducts(normalized))
      return res.data
    })
  }
