import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { H3, P, Col, Row, Button, ToggleableInput, Translate, InfoIcon, deviceUtils } from '@jstack/libema-design-system'
import PriceLabel from './PriceLabel'

const WarningMessage = styled(P)`
  margin-top: ${({ theme }) => theme.margin.sm};
`

const Image = styled.img`
  width: 100%;
  height: auto;
`

const ProductCol = styled.div<{ useImage: boolean }>`
  background-color: ${({ theme }) => theme.color.neutralLight};
  margin-bottom: ${({ theme }) => theme.margin.sm};
  padding: ${({ theme }) => `${theme.padding.md} ${theme.padding.lg}`};
  ${({ useImage }) =>
    useImage &&
    css`
      padding-top: 25px;
    `};

  @media ${deviceUtils.DEVICE_MD_DOWN} {
    padding: 20px;
  }
`

const FromPrice = styled(PriceLabel)`
  font-size: 12px;
  text-decoration: line-through;
  color: #e84d0e;
  margin-right: ${({ theme }) => theme.margin.sm};
`

const PriceCol = styled.div`
  text-align: right;
`

const Title = styled(H3)`
  margin-bottom: 0.5rem;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
`

const SubTitle = styled.span`
  display: block;
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const ToggleButton = styled(Button)`
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.md}`};
`

const RightCorner = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.padding.sm};
  right: ${({ theme }) => theme.padding.sm};
`

interface ProductRowProps {
  image?: string
  isEditable?: boolean
  max?: number
  min?: number
  maxQuantity?: number
  onChange?: (value: number) => void
  price: number
  from_price?: number
  quantity: number
  subTitle: string | null
  title: string
  voucher?: string
  tooltip?: string | null
}

const ProductRow: React.FC<ProductRowProps> = ({
  isEditable,
  image,
  min = 0,
  max = 0,
  maxQuantity = 0,
  onChange,
  price,
  from_price,
  quantity,
  subTitle,
  title,
  tooltip,
  voucher,
}) => {
  const [isDeviceMobile, setIsDeviceMobile] = useState(false)

  useEffect(() => {
    const listenScreenResize = () => {
      if (window.screen.width < deviceUtils.DEVICE_LG) {
        setIsDeviceMobile(true)
      } else {
        setIsDeviceMobile(false)
      }
    }
    window.addEventListener('resize', listenScreenResize)
    return () => window.removeEventListener('resize', listenScreenResize)
  }, [])

  return (
    <ProductCol useImage={!isDeviceMobile && !!image} data-class="product-row">
      <Row>
        {!isDeviceMobile && (
          <Col col={5} lg={3}>
            {image && <Image src={image} />}
          </Col>
        )}
        <Col col={image && !isDeviceMobile ? 7 : 12} lg={image && !isDeviceMobile ? 9 : 12}>
          <Row>
            <Col col={tooltip ? 9 : 12}>
              <Title>{title}</Title>
              {subTitle && <SubTitle>{subTitle}</SubTitle>}
            </Col>
            <Col col={tooltip ? 3 : 0}>
              {tooltip && (
                <RightCorner>
                  <InfoIcon placement="top" content={tooltip} />
                </RightCorner>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col col={6}>
          {typeof voucher === 'undefined' && isEditable && onChange && (
            <ToggleableInput min={min} max={max} value={quantity} onChange={onChange} name={title} />
          )}
          {typeof voucher !== 'undefined' && onChange && (
            <>
              {(typeof quantity === 'undefined' || quantity <= 0) && (
                <ToggleButton onClick={() => onChange(1)} disabled={max < 1}>
                  <Translate id="button.add" />
                </ToggleButton>
              )}
              {quantity > 0 && (
                <ToggleButton onClick={() => onChange(0)}>
                  <Translate id="button.remove" />
                </ToggleButton>
              )}
            </>
          )}
        </Col>
        <Col col={6}>
          <PriceCol>
            {from_price != null && <FromPrice value={from_price} />}
            <PriceLabel value={price} />
          </PriceCol>
        </Col>
      </Row>
      {maxQuantity !== 0 && quantity >= maxQuantity && (
        <Row>
          <Col col={12}>
            <WarningMessage>
              <Translate id="error.max_tickets_product_quantity" values={{ title }} />
            </WarningMessage>
          </Col>
        </Row>
      )}
    </ProductCol>
  )
}

export default ProductRow
