type Link = {
  title: string
  href: string
}

export type CalendarInfoMessage = {
  key: string
  links: {
    [locale: string]: Link
  }
}
export interface Account {
  cookiebot_id: string
  disclaimer: { [locale: string]: string }
  id: string
  useArrangements?: boolean
  headerAlert?: {
    text: string
    link: {
      en: string
      nl: string
    }
    startDate?: string
    endDate?: string
  }
  name: string
  email: string
  title: string
  domain: string
  phone_number: string
  redirectDomain: string
  languages: string[]
  gtm_affiliation: string
  timeFrameAlmostSoldOutThreshold: number
  calendarInfoMessage?: CalendarInfoMessage
  parkregulations: { [locale: string]: string }
  privacystatement: { [locale: string]: string }
  terms: { [locale: string]: string }
  theme: { logo: string }
}

interface EnvState {
  account: Account | null
  device: string
  locale: string
}

export const initialState: EnvState = {
  account: null,
  device: 'desktop',
  locale: 'nl',
}

export default (state = initialState, action: object): EnvState => {
  return state
}
