import React from 'react'
import { connect } from 'react-redux'
import { FieldProps } from 'formik'
import ProductRow from './ProductRow'
import { AppState } from '../../redux/reducers'
import { getOrderProductQuantity } from '../../selectors/orderSelectors'

interface ProductFieldProps extends FieldProps {
  id: string
  image?: {
    thumbnail: string
  }
  isEditable?: boolean
  max: number
  min: number
  maxQuantity: number
  onChange: (value: number) => void
  price: number
  from_price?: number
  quantity: number
  subTitle: string | null
  title: string
  voucher?: string
  tooltip?: string
}

const ProductField: React.FC<ProductFieldProps> = ({
  field,
  form: { setFieldValue },
  image,
  max,
  min,
  maxQuantity,
  onChange,
  title,
  subTitle,
  tooltip,
  price,
  from_price,
  quantity,
  voucher,
}) => {
  React.useEffect(() => {
    // If the quantity was changed outside of this field, we manually update the field value
    // since the form state is kept locally (using formik)
    if (quantity !== parseInt(field.value, 10)) {
      setFieldValue(field.name, quantity)
    }
  }, [quantity])

  return (
    <ProductRow
      image={image?.thumbnail}
      isEditable={true}
      max={max}
      min={min}
      maxQuantity={maxQuantity}
      onChange={(newValue: number) => {
        setFieldValue(field.name, newValue)

        onChange(newValue)
      }}
      price={price}
      from_price={from_price}
      quantity={field.value}
      subTitle={subTitle}
      title={title}
      tooltip={tooltip}
      voucher={voucher}
    />
  )
}

const mapState = (state: AppState, ownProps: ProductFieldProps) => ({
  quantity: getOrderProductQuantity(state, ownProps.id),
})

export default connect(mapState)(ProductField)
