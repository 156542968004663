import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import moment from 'moment'
import styled from 'styled-components'
import {
  H1,
  H2,
  H3,
  Hr,
  StickyRow,
  SubTitle,
  Col,
  RTEContent,
  Row,
  Button,
  ButtonGroup,
  Alert,
  AlertType,
  Modal,
  Rect,
  Translate,
  deviceUtils,
} from '@jstack/libema-design-system'
import ShoppingCart from '../components/shopping/ShoppingCart'
import ProductDates from '../components/shopping/ProductDates'
import ProductsForm from '../components/shopping/ProductsForm'
import { AppState } from '../redux/reducers'
import { getAccount, getAccountName, getTimeFrameAlmostSoldOutThreshold } from '../selectors/envSelectors'
import { ActivityDateTitle, ProductDateTitle } from '../components/shopping/DateTitle'
import { ThunkDispatch } from '../redux/types'
import { verifyOrderStatus, OrderStatusResult, resetState, ResetStateAction } from '../actions/orderActions'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import DefaultTimeframeSelector from '../components/shopping/DefaultTimeframeSelector'
import TimeframeSelectorSomerdrome from '../components/shopping/TimeframeSelectorSomerdrome'
import { Timeframe } from '../api/productApi'
import { Page } from '../api/pageApi'
import CheckoutButton from '../components/shopping/CheckoutButton'
import productTypes from '../constants/productTypes'
import { getTotalTicketsByExposition } from '../selectors/orderSelectors'
import TimeframesUnavailable from '../components/shopping/TimeframesUnavailable'
import routeUtils from '../utils/routeUtils'
import analyticsUtils from '../utils/analyticsUtils'
import { ProductState } from '../redux/productReducer'
import ActivityBlock from '../components/shopping/ActivityBlock'
import { fetchExposition as fetchExpositionRequest } from '../actions/expositionActions'
import { Exposition } from '../api/expositionApi'
import { ACCOUNT_NAMES } from '../data/accounts'
import { hasTimeframes } from '../selectors/timeframeSelectors'
import { Account } from '../redux/envReducer'

const MAX_TICKETS = 10
const SOMER_BEGIN = moment('2022-04-15 00:00:00')
const SOMER_ENDS = moment('2022-09-04 23:59:59')
const SOMER_PAGES = [
  '/somer',
  '/en/somer',
  '/fr/somer',
  '/de/somer',
  '/bestellen',
  '/en/order',
  '/fr/commander',
  '/de/bestellen',
  '/reserveren',
  '/en/reservation',
  '/fr/reserver',
  '/de/reservieren',
  '/abonnement',
  '/en/subscription',
  '/fr/abonnement',
  '/de/abonnement',
  '/kortingsbon',
  '/en/coupon',
  '/fr/coupon',
  '/de/rabatt',
]

const AdditionalProductsTitle = styled(H2)`
  margin-top: ${({ theme }) => theme.margin.md};
  text-align: left;
`

const ModalContent = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.md};
`

interface TicketsBaseProps {
  page: Page
}

interface TicketsProps extends RouteComponentProps, TicketsBaseProps {
  accountName: string
  account: Account | null
  totalTickets: number
  tickets: ProductState
  hasAvailableTimeFrames?: boolean
  timeFrameAlmostSoldOutThreshold: number

  exposition: Exposition | null
  verifyOrder: () => Promise<OrderStatusResult>
  resetOrderState: () => ResetStateAction
  fetchExposition: (expositionId: string) => Promise<Exposition>
}

interface TicketsState {
  date: moment.Moment | null
  // The orderKey is used to force re-mounting of the productsform when a user wants to start a new order
  // This is necessary because the form fields manage their own state and clearing the redux state
  // will leave form field state unchanged.
  // See https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
  orderKey: number
  timeframe?: Timeframe
  unfinishedOrderModal: boolean
  pageLoaded: boolean
}

class Tickets extends React.Component<TicketsProps, TicketsState> {
  productsRef = React.createRef<HTMLDivElement>()

  constructor(props: TicketsProps) {
    super(props)

    this.state = {
      date: null,
      orderKey: 1,
      timeframe: undefined,
      unfinishedOrderModal: false,
      pageLoaded: false,
    }
  }

  componentDidMount = () => {
    routeUtils.setShopEntry(this.props.match.url)
    this.props.fetchExposition(this.props.page.exposition_id)
    this.props.verifyOrder().then((data) => {
      if (data.status === 'pending') {
        this.setState({ unfinishedOrderModal: true })
        if (typeof window !== 'undefined' && this.state.unfinishedOrderModal === true) {
          analyticsUtils.pushEvent({
            event: 'TriggeredUnfinishedOrderModal',
          })
        }
      }
    })

    analyticsUtils.virtualPageView()
  }

  componentDidUpdate = (prevProps: TicketsProps, prevState: TicketsState) => {
    const { timeframe, pageLoaded } = this.state
    const {
      tickets,
      page: { products },
    } = this.props

    // Wait until the date_ticket product be loaded.
    if (Object.keys(prevProps.tickets).length === 0 && Object.keys(tickets).length > 0 && !pageLoaded && timeframe) {
      this.setState({ pageLoaded: true })
      analyticsUtils.productImpressions(Object.values(tickets).concat(products), timeframe)
    }
  }

  setDate = (date: moment.Moment | null) => {
    this.setState({ date })
  }

  setTimeFrame = (timeframe?: Timeframe) => {
    if (this.state.timeframe?.id !== timeframe?.id) {
      this.setState({ timeframe })
      this.setState({ pageLoaded: false })
    }
  }

  closeModal = () => this.setState({ unfinishedOrderModal: false })

  newOrder = () => {
    this.setState((prevState) => ({ orderKey: prevState.orderKey + 1 }))
    this.props.resetOrderState()
    this.closeModal()
  }

  render = () => {
    const { page, totalTickets, hasAvailableTimeFrames, account } = this.props
    let pathname = ''
    if (typeof window !== 'undefined') {
      pathname = window.location.pathname
    }

    const isSomerdrome =
      this.props.accountName === ACCOUNT_NAMES.BEEKSEBERGEN &&
      this.state.date?.isBetween(SOMER_BEGIN, SOMER_ENDS, undefined, '[]') &&
      !!SOMER_PAGES.find((path) => pathname.includes(path))
    const maxTickets = this.props.exposition?.maxTickets ?? MAX_TICKETS
    const TimeframeSelector = isSomerdrome ? TimeframeSelectorSomerdrome : DefaultTimeframeSelector

    return (
      <>
        <Helmet>
          <title>{page.title}</title>
        </Helmet>
        <Modal
          isOpen={this.state.unfinishedOrderModal}
          // onClose={this.newOrder}
        >
          <ModalContent>
            <Translate id="title.unfinished_order" />
          </ModalContent>
          <ButtonGroup>
            {/* <Button onClick={this.closeModal}><Translate id="button.order_edit" /></Button> */}
            <Button onClick={this.newOrder}>
              <Translate id="button.order_new" />
            </Button>
          </ButtonGroup>
        </Modal>
        <Row>
          <Col col={12}>
            <H1 centered={true}>{page.title}</H1>
            <SubTitle>
              <RTEContent dangerouslySetInnerHTML={{ __html: page.description }} />
            </SubTitle>
          </Col>
        </Row>
        <Row>
          <Col col={12} md={7}>
            <H3>
              <Translate id="title.arrival_date" />
            </H3>
            <ProductDates expositionId={page.exposition_id} onDateChange={this.setDate} />
          </Col>

          <Col col={12} md={5}>
            <Row>
              <Col col={12}>
                <H3>
                  <Translate id="title.timeframes" />
                </H3>
              </Col>
              <Col col={12}>
                {this.state.date !== null && (
                  <TimeframeSelector
                    date={this.state.date}
                    expositionId={page.exposition_id}
                    value={this.state.timeframe?.id}
                    timeFrameAlmostSoldOutThreshold={this.props.timeFrameAlmostSoldOutThreshold}
                    onButtonClick={() => {
                      if (this.productsRef.current !== null) {
                        window.scrollTo({
                          top: deviceUtils.getDistanceToTop(this.productsRef.current),
                          left: 0,
                          behavior: 'smooth',
                        })
                      }
                    }}
                    onChange={this.setTimeFrame}
                  />
                )}
                {this.state.date === null && <Rect />}
              </Col>
            </Row>
          </Col>

          {/* {typeof this.state.timeframe !== 'undefined' && (
            <Col col={12}>
              <div ref={this.productsRef}>
                <ProductDateTitle from={this.state.timeframe.from} until={this.state.timeframe.until} />
              </div>
            </Col>
          )} */}
          <Col>
            <Hr />
          </Col>

          <Col col={12} md={7}>
            {this.state.date !== null && typeof this.state.timeframe === 'undefined' && <TimeframesUnavailable />}
            {typeof this.state.timeframe !== 'undefined' && (
              <>
                {hasAvailableTimeFrames && (
                  <div ref={this.productsRef}>
                    <ProductDateTitle from={this.state.timeframe.from} until={this.state.timeframe.until} />
                  </div>
                )}
                <ProductsForm
                  key={this.state.orderKey}
                  expositionId={page.exposition_id}
                  max={maxTickets}
                  timeframeId={this.state.timeframe?.id}
                  type={productTypes.DATE}
                />
                {maxTickets > 0 && totalTickets === maxTickets && (
                  <Alert type={AlertType.WARNING}>
                    <Translate id="error.max_tickets_reached" />
                  </Alert>
                )}
                {maxTickets > 0 && totalTickets > maxTickets && (
                  <Alert type={AlertType.ERROR}>
                    <Translate id="error.max_tickets_exceeded" />
                  </Alert>
                )}
                {page.activities.length > 0 && this.state.date && this.state.timeframe && (
                  <AdditionalProductsTitle centered={true}>
                    <ActivityDateTitle date={this.state.timeframe.from} useArrangements={account?.useArrangements} />
                  </AdditionalProductsTitle>
                )}
                {this.state.date &&
                  this.state.timeframe &&
                  page.activities.map((expositionId) => (
                    <ActivityBlock key={expositionId} expositionId={expositionId} mainTimeframe={this.state.timeframe as Timeframe} />
                  ))}
                {page.products.length > 0 && (
                  <>
                    <AdditionalProductsTitle centered={true}>
                      <Translate id="title.additional_products" />
                    </AdditionalProductsTitle>
                    <ProductsForm key={this.state.orderKey} products={page.products} type={productTypes.STANDARD} max={maxTickets} />
                  </>
                )}
              </>
            )}
          </Col>
          <Col col={12} md={5}>
            <H3>
              <Translate id="title.shopping_cart" />
            </H3>
            <StickyRow>
              <ShoppingCart basketInfoLines={this.props.page.basketInfoLines} />
            </StickyRow>
          </Col>
        </Row>
        <Row>
          <Col col={12} md={7}>
            {/*
              We only render the checkout button on the client side, since serverside rendering might
              bring the button in an incorrect state when cart products are being preloaded from local storage
            */}
            {typeof window !== 'undefined' && <CheckoutButton />}
          </Col>
        </Row>
      </>
    )
  }
}

const mapState = (state: AppState, props: TicketsBaseProps) => ({
  exposition: state.exposition,
  account: getAccount(state),
  accountName: getAccountName(state),
  timeFrameAlmostSoldOutThreshold: getTimeFrameAlmostSoldOutThreshold(state),
  hasAvailableTimeFrames: hasTimeframes(state),
  totalTickets: getTotalTicketsByExposition(state, props.page.exposition_id),
  tickets: state.products,
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  fetchExposition: (expositionId: string) => dispatch(fetchExpositionRequest(expositionId)),
  resetOrderState: () => dispatch(resetState()),
  verifyOrder: () => dispatch(verifyOrderStatus()),
})

export default withRouter(connect(mapState, mapDispatch)(Tickets))
