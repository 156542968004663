import de from './de';
import en from './en';
import fr from './fr';
import nl from './nl';

export default {
  de,
  en,
  fr,
  nl,
};
