import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import ShoppingCart from '../components/shopping/ShoppingCart'
import VoucherForm from '../components/shopping/VoucherForm'
import { ThunkDispatch } from '../redux/types'
import { verifyOrderStatus } from '../actions/orderActions'
import CheckoutButton from '../components/shopping/CheckoutButton'
import Base from './Base'
import { CustomTheme } from '../theme'
import { H2, H3, StickyRow, SubTitle, Col, Row, Center, Translate } from '@jstack/libema-design-system'

interface VouchersProps {
  isIE: boolean
  theme: CustomTheme
  verifyOrder: () => void
}

class Vouchers extends React.Component<VouchersProps, {}> {
  static async getInitialProps(props: VouchersProps) {
    return {
      isIE: props.isIE,
      theme: props.theme,
    }
  }

  componentDidMount = () => {
    this.props.verifyOrder()
  }

  render = () => {
    const { theme, isIE } = this.props

    return (
      <Base theme={theme} isIE={isIE}>
        <Helmet>
          <title>Vouchers</title>
        </Helmet>
        <Row>
          <Col col={12}>
            <H2 centered={true}><Translate id="title.vouchers"/></H2>
            <Center>
              <SubTitle>
                <Translate id="title.vouchers_subtitle" />
              </SubTitle>
            </Center>
          </Col>
          <Col col={12} md={7}>
            <H3><Translate id="title.vouchers_validate" /></H3>
            <VoucherForm />
          </Col>
          <Col col={12} md={5}>
            <H3><Translate id="title.shopping_cart" /></H3>
            <StickyRow>
              <ShoppingCart />
            </StickyRow>
          </Col>
        </Row>
        <Row>
          <Col col={12} md={7}>
            <CheckoutButton />
          </Col>
        </Row>
      </Base>
    )
  }
}

const mapDispatch = (dispatch: ThunkDispatch) => ({
  verifyOrder: () => dispatch(verifyOrderStatus())
})

export default connect(null, mapDispatch)(Vouchers)
