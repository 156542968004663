import {
  SET_CART,
  SetCartAction,
  SET_CUSTOMER,
  SetCustomerAction,
  SET_CART_PENDING,
  SetCartPendingAction,
  SetOrderProductsAction,
  SET_ORDER_PRODUCTS,
  UpdateOrderProductAction,
  UPDATE_ORDER_PRODUCT,
  SetIdAction,
  SET_ID,
  ResetStateAction,
  RESET_STATE
} from '../actions/orderActions'
import { OrderProduct } from '../api/orderApi'
import productUtils from '../utils/productUtils'

type Actions = ResetStateAction | SetIdAction | SetCartAction | SetCustomerAction
  | SetCartPendingAction | SetOrderProductsAction | UpdateOrderProductAction

interface CartItemArticle {
  id: string
}

interface LockTicket {
  $type: string
  expirationTime: string
  id: string
}

interface CartItemEntry {
  $type: string
  amount: number
  participantCount: number
  priceGroupId: string
  unitPrice: number
}

export interface CartItem {
  $type: string
  entries?: CartItemEntry[]
  expositionPeriodId?: string
  id: string
  lockTicket?: LockTicket
  quantity: number
  unitPrice: number
  article: CartItemArticle
}

interface CartState {
  customerId?: string
  items?: CartItem[]
  price?: number
}

export interface CustomerState {
  email: string
  first_name: string
  last_name: string
  newsletter: boolean
  terms: boolean
  phone?: string
}

export interface OrderState {
  // If the order was confirmed, the ID is kept here for later reference
  id?: string
  // The validated shopping cart data returned by ReCreateX
  cart: CartState
  // A boolean that tells you if the cart is waiting on an update
  cartPending: boolean
  // The customer data (name, email, etc.)
  customer?: CustomerState
  // Products that were added to the order by the user.
  // Other than the `cart` property, these products are not validated by ReCreateX yet.
  products: OrderProduct[]
}

export const initialState: OrderState = {
  cart: {},
  cartPending: false,
  products: [],
}

export default (state = initialState, action: Actions): OrderState => {
  switch (action.type) {
    case RESET_STATE: {
      return { ...initialState }
    }
    case SET_ID: {
      return {
        ...state,
        id: action.payload.id
      }
    }
    case SET_CART: {
      return {
        ...state,
        cartPending: false,
        cart: {
          ...action.cart
        }
      }
    }
    case SET_CART_PENDING: {
      return {
        ...state,
        cartPending: action.payload
      }
    }
    case SET_CUSTOMER: {
      return {
        ...state,
        customer: {
          ...action.payload
        }
      }
    }
    case SET_ORDER_PRODUCTS: {
      return {
        ...state,
        products: [...action.payload]
      }
    }
    case UPDATE_ORDER_PRODUCT: {
      return {
        ...state,
        products: productUtils.updateCollection(state.products, action.payload),
      }
    }
    default: {
      return state
    }
  }
}
