import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Checkout from './Checkout'
import { CustomTheme } from '../theme'
import Base from './Base'

interface AppProps {
  isIE: boolean
  theme: CustomTheme
}

class App extends Component<AppProps, {}> {
  static async getInitialProps(props: AppProps) {
    return {
      isIE: props.isIE,
      theme: props.theme,
    }
  }

  render = () => {
    const { theme, isIE } = this.props

    return (
      <Base theme={theme} isIE={isIE}>
        <Switch>
          <Route path={['/checkout', '/en/checkout', '/fr/checkout', '/de/checkout']} exact={true} component={Checkout} />
        </Switch>
      </Base>
    )
  }
}

export default App
