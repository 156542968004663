import React from 'react'
import styled from 'styled-components'
import { CustomTheme } from '../theme'
import Base from './Base'
import { Center, H1, P, Translate } from '@jstack/libema-design-system'

const Main = styled.div`
  margin: 50px 0;
  min-height: 400px;
`

interface NotFoundProps {
  isIE: boolean
  theme: CustomTheme
}

const NotFound: React.FC<NotFoundProps> = ({ theme, isIE }) => (
  <Base theme={theme} isIE={isIE}>
    <Main>
      <Center>
        <H1><Translate id="title.404" /></H1>
        <P><Translate id="title.404_subtitle" /></P>
      </Center>
    </Main>
  </Base>
)

export default NotFound
