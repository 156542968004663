import { AxiosPromise } from 'axios'
import { get } from '.'

export interface ExpositionOptionalProduct {
  id: string
  name: string
  price: number
  type: string
}

export interface Exposition {
  id: string
  name: string
  description: string
  maxTickets: number
  optional_products: ExpositionOptionalProduct[]
}

export interface ExpositionDate {
  date: string
  is_available: boolean
}

export const getExposition = (accountId: string, expositionId: string, locale: string): AxiosPromise<Exposition> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/expositions/${expositionId}/${locale}`,
})

export const getExpositionDates = (
  accountId: string,
  expositionId: string,
  params: object = {}
): AxiosPromise<ExpositionDate[]> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/expositions/${expositionId}/dates`,
  params
})
