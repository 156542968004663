import React from 'react'
import { Provider } from 'react-redux'
import { hydrate } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ensureReady, After } from '@jaredpalmer/after'
import * as Sentry from '@sentry/browser'
import routes from './routes'
import configureStore from './redux/store'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    beforeBreadcrumb: (breadcrumb, hint) => {
      // Skip console logs from breadcrumbs to avoid numerous GTM console stuff
      return breadcrumb.category === 'console' ? null : breadcrumb
    },
    dsn: 'https://8ddf29385f6f4b07b774dad0088e44eb@o204295.ingest.sentry.io/3140036',
    environment: process.env.RAZZLE_STAGE,
    // IgnoreErrors & blacklistUrls are taken from https://docs.sentry.io/platforms/javascript/#decluttering-sentry
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'fbq is not defined',
      'Can\'t find variable: fbq',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      '$ is not defined'
    ],
    blacklistUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
  })
}

const store = configureStore((window as any).__PRELOADED_STATE__)

ensureReady(routes).then(data => hydrate(
  <BrowserRouter>
    <Provider store={store}>
      {/* @ts-ignore because of https://github.com/jaredpalmer/after.js/issues/373 */}
      <After data={data} routes={routes} />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
))

if (module.hot) {
  module.hot.accept()
}
