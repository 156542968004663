import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled, { css } from 'styled-components'
import * as Sentry from '@sentry/browser'
import { Col, Row, Button, deviceUtils, Translate } from '@jstack/libema-design-system'
import { AppState } from '../../../redux/reducers'
import { getAvailableTimeframesForDate } from '../../../selectors/timeframeSelectors'
import { Timeframe } from '../../../api/productApi'
import TimeframesUnavailable from '../TimeframesUnavailable'
import { ThunkDispatch } from '../../../redux/types'
import { getAccountName } from '../../../selectors/envSelectors'
import { ACCOUNT_NAMES } from '../../../data/accounts'
import { fetchTimeframes as fetchTimeframesRequest } from '../../../actions/productActions'
import Loader from '../../loaders/Loader'

import someravond from './someravond.png'
import somerdag from './somerdag.png'
import somer from './somer.png'
import openedHours from './openedHours'

interface TimeframeGroupProps {
  selectedDate?: moment.Moment
  time: 'day' | 'evening'
  img?: string
  timeframes: Timeframe[]
  onChange: (timeframe?: Timeframe) => void
  value?: string
  accountName: string
}

interface TimeframeSelectorBaseProps {
  date: moment.Moment
  expositionId: string
  timeFrameAlmostSoldOutThreshold: number
  onButtonClick: () => void
  onChange: (timeframe?: Timeframe) => void
  // The ID of the timeframe
  value?: string
}

interface TimeframeSelectorProps extends TimeframeSelectorBaseProps {
  fetchTimeframes: (params: object) => Promise<Timeframe[]>
  accountName: string
  timeframes: Timeframe[]
}

const ImageBlock = styled.div`
  text-align: center;

  img {
    height: 256px;
  }

  @media ${deviceUtils.DEVICE_LG_DOWN} and ${deviceUtils.DEVICE_MD_UP} {
    img {
      max-width: 100%;
      height: auto;
    }
  }
`

const TimeframeSelectorContainer = styled.div`
  background-color: ${({ theme }) => theme.color.neutralLight};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: ${({ theme }) => theme.padding.md};
`

const TimeframeTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
  font-weight: bold;
`

const TimeframeOptionGroup = styled(Row)`
  display: flex;
  margin: 0 -5px 1rem;

  > * {
    padding: 4px;
  }
`

const Option = styled(Button)<{ checked: boolean; disabled: boolean; time: 'day' | 'evening' }>`
  font-size: 1rem;
  background-color: ${({ theme }) => theme.color.neutralMedium};
  border: 2px solid ${({ checked }) => (checked ? 'red' : 'transparent')};
  color: white;
  min-height: 48px;
  width: 100%;
  padding: 1rem;

  &:hover {
    background-color: #3b4521;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: repeating-linear-gradient(45deg, #fff, #fff 8px, #f8d7da 8px, #f8d7da 16px);
      border: none;
      color: black;
      &:hover {
        color: black;
      }
    `}

  ${({ time }) =>
    time === 'evening' &&
    css`
      background-color: #c08f16;
      &:hover {
        background-color: #977111;
      }
    `}
`
const OpenedHoursLabel = ({
  options: { time, selectedDayOpenedHours },
}: {
  options: {
    time: TimeframeGroupProps['time']
    selectedDayOpenedHours: string
  }
}) => {
  if (!selectedDayOpenedHours) {
    return null;
  }
  const label = time === 'day' ? selectedDayOpenedHours : `13:00-${selectedDayOpenedHours.split('-').pop()}`
  return <>{label}</>
}

const TimeframeGroup: React.FC<TimeframeGroupProps> = ({ selectedDate, time, img, timeframes, onChange, value, accountName }) => {
  const isBeeskeBergen = accountName === ACCOUNT_NAMES.BEEKSEBERGEN
  const isEvening = time === 'evening'
  const selectedDayOpenedHours: string = selectedDate ? openedHours[selectedDate.format('YYYY-MM-DD')] : null
  return (
    <>
      {img ? (
        <ImageBlock>
          <img src={img} />
        </ImageBlock>
      ) : null}
      <TimeframeTitle>
        <Col col={4}>
          {selectedDayOpenedHours && (
            <Translate
              id={`tickets.timeframe_v2.tabs_${time}`}
              values={{
                strong: (chunks: any) => <strong>{chunks}</strong>,
              }}
            />
          )}
        </Col>
        <Col col={4}>
          <OpenedHoursLabel options={{ time, selectedDayOpenedHours }} />
        </Col>
        <Col col={4}>
          <Translate
            id={`tickets.timeframe.tabs_${time}.price${isBeeskeBergen && isEvening ? '_beeksebergen' : ''}`}
            values={{
              strong: (chunks: any) => <strong>{chunks}</strong>,
            }}
          />
        </Col>
      </TimeframeTitle>
      <TimeframeOptionGroup>
        {timeframes.map((timeframe) => (
          <Col key={timeframe.id} col={6} lg={4}>
            <Option
              key={timeframe.id}
              id={`timeframe-${timeframe.id}`}
              time={time}
              checked={value === timeframe.id}
              disabled={timeframe.availability < 1}
              onClick={() => onChange(timeframe)}
              value={timeframe.id}
            >
              {`${moment(timeframe.from).format('H:mm')} - ${moment(timeframe.until).format('H:mm')}`}
            </Option>
          </Col>
        ))}
      </TimeframeOptionGroup>
    </>
  )
}

const TimeframeSelectorSomerdromeV2: React.FC<TimeframeSelectorProps> = ({
  date,
  expositionId,
  fetchTimeframes,
  timeframes,
  timeFrameAlmostSoldOutThreshold,
  onButtonClick,
  onChange,
  value,
  accountName,
}) => {
  const [isLoading, setLoading] = React.useState<boolean>(true)
  const [timeframesDay, setTimeframesDay] = React.useState<Timeframe[]>([])
  const [timeframesEvening, setTimeframesEvening] = React.useState<Timeframe[]>([])

  React.useEffect(() => {
    setLoading(true)
    const today = moment().add(5, 'minutes')
    const isToday = date.isSame(today, 'date')
    const from = isToday ? today.format('YYYY-MM-DD[T]HH:mm:ss') : date.format('YYYY-MM-DD')
    const until = date.clone().add(1, 'day').format('YYYY-MM-DD')

    fetchTimeframes({ exposition_id: expositionId, from, until })
      .then((response: Timeframe[]) => {
        setLoading(false)

        if (response.length) {
          const dayTimeframes: Timeframe[] = []
          const eveningTimeframes: Timeframe[] = []
          response.forEach((timeframe) => {
            const hour = moment(timeframe.from).hour()
            if (hour < 13) {
              dayTimeframes.push(timeframe)
            } else {
              eveningTimeframes.push(timeframe)
            }
          })
          setTimeframesDay(dayTimeframes)
          setTimeframesEvening(eveningTimeframes)

          const first = !dayTimeframes.length
            ? eveningTimeframes.find((timeframe) => timeframe.availability > 0)
            : dayTimeframes.find((timeframe) => timeframe.availability > 0)

          // Whenever the `date` changes, we automatically set the first available timeframe
          onChange(first || undefined)
        }
      })
      .catch((e: Error) => {
        Sentry.captureException(e)
      })
      .then(() => {
        setLoading(false)
      })
  }, [date])

  const firstAvailable = timeframes.find((timeframe) => timeframe.availability > 0)

  if (!isLoading && (timeframes.length < 1 || !firstAvailable)) {
    return (
      <TimeframeSelectorContainer>
        <TimeframesUnavailable />
      </TimeframeSelectorContainer>
    )
  }

  if (isLoading) {
    return (
      <TimeframeSelectorContainer>
        <Loader />
      </TimeframeSelectorContainer>
    )
  }

  return (
    <TimeframeSelectorContainer>
      <ImageBlock>
        <img src={somer} />
      </ImageBlock>
      {timeframesDay.length > 0 && (
        <TimeframeGroup selectedDate={date} time="day" timeframes={timeframesDay} onChange={onChange} value={value} accountName={accountName} />
      )}
      {timeframesEvening.length > 0 && (
        <TimeframeGroup selectedDate={date} time="evening" timeframes={timeframesEvening} onChange={onChange} value={value} accountName={accountName} />
      )}
      <Button fullWidth={true} onClick={onButtonClick}>
        <Translate id="button.to_products" />
      </Button>
    </TimeframeSelectorContainer>
  )
}

const mapState = (state: AppState, props: TimeframeSelectorBaseProps) => ({
  accountName: getAccountName(state),
  timeframes: getAvailableTimeframesForDate(state, props.date.format('YYYY-MM-DD')),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  fetchTimeframes: (params: object) => dispatch(fetchTimeframesRequest(params)),
})

export default connect(mapState, mapDispatch)(TimeframeSelectorSomerdromeV2)
