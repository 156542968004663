import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import * as Sentry from '@sentry/browser'
import { Button, OptionGroup as BaseOptionGroup, Radio, Translate } from '@jstack/libema-design-system'
import { AppState } from '../../redux/reducers'
import { getAvailableTimeframesForDate } from '../../selectors/timeframeSelectors'

import productUtils from '../../utils/productUtils'
import { Timeframe } from '../../api/productApi'
import TimeframesUnavailable from './TimeframesUnavailable'
import { ThunkDispatch } from '../../redux/types'
import { fetchTimeframes as fetchTimeframesRequest } from '../../actions/productActions'
import Loader from '../loaders/Loader'

interface TimeframeSelectorBaseProps {
  date: moment.Moment
  timeFrameAlmostSoldOutThreshold: number
  expositionId: string
  onButtonClick: () => void
  onChange: (timeframe?: Timeframe) => void
  // The ID of the timeframe
  value?: string
}

interface TimeframeSelectorProps extends TimeframeSelectorBaseProps {
  fetchTimeframes: (params: object) => Promise<Timeframe[]>
  timeframes: Timeframe[]
}

// This bottom-margin can be removed as soon as @jstack/libema-design-system@0.1.18 is released
const OptionGroup = styled(BaseOptionGroup)`
  margin-bottom: ${({ theme }) => theme.margin.sm};
`

const TimeframeSelectorContainer = styled.div`
  background-color: ${({ theme }) => theme.color.neutralLight};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: ${({ theme }) => theme.padding.md};
  padding-bottom: ${({ theme }) => theme.padding.md};
`

const Description = styled.p`
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const Options = styled.div`
  flex-grow: 1;
  margin-bottom: ${({ theme }) => theme.margin.md};
`

interface LabelProps {
  disabled?: boolean
}

const Label = styled.label<LabelProps>`
  text-decoration: ${({ disabled }) => (disabled === true ? 'line-through' : 'none')};
`

const DefaultTimeframeSelector: React.FC<TimeframeSelectorProps> = ({
  date,
  expositionId,
  fetchTimeframes,
  timeFrameAlmostSoldOutThreshold,
  timeframes,
  onButtonClick,
  onChange,
  value,
}) => {
  const [isLoading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    setLoading(true)
    const today = moment().add(5, 'minutes')
    const isToday = date.isSame(today, 'date')
    const from = isToday ? today.format('YYYY-MM-DD[T]HH:mm:ss') : date.format('YYYY-MM-DD')
    const until = date.clone().add(1, 'day').format('YYYY-MM-DD')

    fetchTimeframes({ exposition_id: expositionId, from, until })
      .then((response: Timeframe[]) => {
        setLoading(false)
        const first = response.find((timeframe) => timeframe.availability > 0)

        // Whenever the `date` changes, we automatically set the first available timeframe
        if (first) {
          onChange(first)
        } else {
          onChange(undefined)
        }
      })
      .catch((e: Error) => {
        setLoading(false)
        Sentry.captureException(e)
      })
  }, [date])

  const firstAvailable = timeframes.find((timeframe) => timeframe.availability > 0)

  if (!isLoading && (timeframes.length < 1 || !firstAvailable)) {
    return (
      <TimeframeSelectorContainer data-class="timeframe-selector">
        <TimeframesUnavailable />
      </TimeframeSelectorContainer>
    )
  }

  return (
    <TimeframeSelectorContainer data-class="timeframe-selector">
      <Description>
        <Translate id="title.timeframes_subtitle" />
      </Description>
      {isLoading && <Loader />}
      <Options>
        {timeframes.map((timeframe) => (
          <OptionGroup key={timeframe.id} inline={true}>
            <Radio
              key={timeframe.id}
              id={`timeframe-${timeframe.id}`}
              checked={value === timeframe.id}
              disabled={timeframe.availability < 1}
              onChange={() => onChange(timeframe)}
              value={timeframe.id}
            />
            <Label htmlFor={`timeframe-${timeframe.id}`} disabled={timeframe.availability < 1}>
              <Translate
                id="label.timeframe_from_util"
                values={{
                  from: moment(timeframe.from).format('H:mm'),
                  until: moment(timeframe.until).format('H:mm'),
                }}
              />
            </Label>
            {productUtils.isAlmostSoldOut(timeframe, timeFrameAlmostSoldOutThreshold) && (
              <>
                &nbsp;(
                <Translate id="label.almost_sold_out" />)
              </>
            )}
            {productUtils.isSoldOut(timeframe) && (
              <>
                &nbsp;(
                <Translate id="label.sold_out" />)
              </>
            )}
          </OptionGroup>
        ))}
      </Options>
      <Button fullWidth={true} onClick={onButtonClick}>
        <Translate id="button.to_products" />
      </Button>
    </TimeframeSelectorContainer>
  )
}

const mapState = (state: AppState, props: TimeframeSelectorBaseProps) => ({
  timeframes: getAvailableTimeframesForDate(state, props.date.format('YYYY-MM-DD')),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  fetchTimeframes: (params: object) => dispatch(fetchTimeframesRequest(params)),
})

export default connect(mapState, mapDispatch)(DefaultTimeframeSelector)
