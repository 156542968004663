import React, { useState } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RouteComponentProps, Link } from 'react-router-dom'
import ShoppingCart from '../components/shopping/ShoppingCart'
import CheckoutForm from '../components/shopping/CheckoutForm'
import { AppState } from '../redux/reducers'
import { getOrderProducts, hasValidatedCart, isReadyForCheckout } from '../selectors/orderSelectors'
import OrderInvalid from '../components/shopping/OrderInvalid'
import Loader from '../components/loaders/Loader'
import { ThunkDispatch } from '../redux/types'
import { updateCart } from '../actions/orderActions'
import { H1, H3, Col, Row, Center, StickyRow, SubTitle, Translate, ButtonLink } from '@jstack/libema-design-system'
import { OrderProduct } from '../api/orderApi'
import analyticsUtils from '../utils/analyticsUtils'
import { getPage, Page } from '../api/pageApi'
import { findByDomain } from '../data/accounts'
import { CtxBase } from '@jaredpalmer/after'

interface CheckoutProps extends RouteComponentProps {
  page: Page
  cartIsValidated: boolean
  orderIsValid: boolean
  orderProducts: OrderProduct[]
  updateCart: (lock?: boolean) => Promise<void>
}

type AsyncProps = CtxBase & RouteComponentProps

const CheckoutStyled = styled.section`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

// TODO: Verify order on checkout mount when no cart data is available

const Checkout: React.FC<CheckoutProps> = ({ cartIsValidated, orderIsValid, history, match, orderProducts, ...restProps }) => {
  const [page, setPage] = useState<Page | null>(null)

  React.useEffect(() => {
    analyticsUtils.virtualPageView()
  }, [])

  React.useEffect(() => {
    if (!cartIsValidated) {
      restProps.updateCart(true)
    }

    const account = findByDomain(window.location.hostname)
    getPage(account.id, match.url)
      .then((pageResponse) => setPage(pageResponse.data))
      .catch(() => setPage(null))
  }, [])

  React.useEffect(() => {
    if (cartIsValidated && orderIsValid) {
      analyticsUtils.checkoutStep1(orderProducts)
    }
  }, [cartIsValidated, orderIsValid])

  function goBack() {
    history.goBack()
  }

  return (
    <CheckoutStyled>
      <Helmet>
        <title>Checkout</title>
      </Helmet>
      <Row>
        <Col col={12} md={8} mdOffset={2}>
          <Center>
            <H1>
              <Translate id="title.your_order" />
            </H1>
            <SubTitle>
              <Translate id="title.your_order_subtitle" />
            </SubTitle>
          </Center>
          {cartIsValidated && !orderIsValid && <OrderInvalid />}
          {!cartIsValidated && (
            <Center>
              <Loader size="lg" />
            </Center>
          )}
        </Col>
      </Row>
      <Row>
        <Col col={12} md={7}>
          <CheckoutForm checkoutWarningMessage={page?.checkout_warning_message || null} />
        </Col>
        <Col col={12} md={5}>
          <H3>
            <Translate id="title.shopping_cart" />
          </H3>
          <StickyRow>
            <ShoppingCart isFinal={true} />
            <Col col={12}>
              <Center>
                <ButtonLink onClick={goBack} data-class="change-order-button">
                  <Translate id="button.order_edit" />
                </ButtonLink>
              </Center>
            </Col>
          </StickyRow>
        </Col>
      </Row>
    </CheckoutStyled>
  )
}

const mapState = (state: AppState) => ({
  cartIsValidated: hasValidatedCart(state),
  orderIsValid: isReadyForCheckout(state),
  orderProducts: getOrderProducts(state),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  updateCart: (lock?: boolean) => dispatch(updateCart(lock)),
})

export default connect(mapState, mapDispatch)(Checkout)
