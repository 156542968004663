import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, IntlShape } from 'react-intl'
import { AppState } from '../../redux/reducers'
import { getAccountEmail, getLocale } from '../../selectors/envSelectors'
import { H1, Center, RTEContent, SubTitle, Translate } from '@jstack/libema-design-system'

interface PaymentUpdateTimeoutProps {
  intl: IntlShape
  email: string
  locale: string
}

const PaymentUpdateTimeout: React.FC<PaymentUpdateTimeoutProps> = ({ email, intl, locale }) => {
  return (
    <>
      <Center>
        <H1><Translate id="title.order_verification_cancelled" /></H1>
      </Center>
      <SubTitle>
        <RTEContent>
          {locale === 'nl' && (
            <>
              Je betaling is ontvangen, echter is je order nog niet verwerkt.
              Dit duurt langer dan gebruikelijk. Als je binnen een half uur geen e-mail ontvangt en:
              <br />
              <ul>
                <li>Heb je tickets gekocht en kom je vandaag? Neem dan je betaalbewijs mee.</li>
                <li>
                  Heb je tickets gekocht en kom je later? Neem dan contact op via&nbsp;
                  <a href={`mailto:${email}`}>{email}</a>.
                </li>
                <li>
                  Heb je als abonnee of met actietickets tickets gereserveerd,
                  probeer het dan later opnieuw. Excuses voor het ongemak.
                </li>
              </ul>
            </>
          )}

          {locale === 'en' && (
            <>
              Your payment has been received, but your order has not yet been processed.
              This takes longer than usual. If you do not receive an e-mail within half an hour and:
              <br />
              <ul>
                <li>Have you bought tickets and are you coming today? Then bring your proof of payment.</li>
                <li>
                  Have you bought tickets and are you coming later? Please contact us via&nbsp;
                  <a href={`mailto:${email}`}>{email}</a>.
                </li>
                <li>
                  If you have reserved tickets as a subscriber or with promotional tickets, try again later.
                  Sorry for the inconvenience.
                </li>
              </ul>
            </>
          )}

          {locale === 'de' && (
            <>
              Ihre Zahlung wurde empfangen, aber Ihre Bestellung wurde noch nicht bearbeitet.
              Dies dauert länger als gewöhnlich. Wenn Sie innerhalb einer halben Stunde keine E-Mail erhalten und:
              <br />
              <ul>
                <li>Haben Sie Tickets gekauft und kommen Sie heute? Dann bringen Sie Ihren Zahlungsnachweis mit.</li>
                <li>
                  Haben Sie Tickets gekauft und kommen Sie später? Bitte kontaktieren Sie uns über&nbsp;
                  <a href={`mailto:${email}`}>{email}</a>.
                </li>
                <li>
                  Wenn Sie Tickets als Abonnent oder mit Werbekarten reserviert haben, versuchen Sie es später erneut.
                  Entschuldigung für die Unannehmlichkeiten.
                </li>
              </ul>
            </>
          )}
          {locale === 'fr' && (
            <>
              Votre paiement a été reçu, mais votre commande n'a pas encore été traitée.
              Cela prend plus de temps que d'habitude.
              Si vous ne recevez pas d'e-mail dans un délai d'une demi-heure et:
              <br />
              <ul>
                <li>
                  Avez-vous acheté des billets et venez-vous aujourd'hui? Ensuite, apportez votre preuve de paiement.
                </li>
                <li>
                  Avez-vous acheté des billets et venez-vous plus tard? Veuillez nous contacter via&nbsp;
                  <a href={`mailto:${email}`}>{email}</a>.
                </li>
                <li>
                  Si vous avez réservé des billets en tant qu'abonné ou avec des billets promotionnels,
                  veuillez réessayer plus tard. Désolé pour le dérangement.
                </li>
              </ul>
            </>
          )}
        </RTEContent>
      </SubTitle>
    </>
  )
}

const mapState = (state: AppState) => ({
  email: getAccountEmail(state),
  locale: getLocale(state)
})

export default connect(mapState)(injectIntl(PaymentUpdateTimeout))
