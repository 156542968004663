import { Translate } from '@jstack/libema-design-system'
import React from 'react'

interface ApiErrorProps {
  error: string,
  values?: { [key: string]: string | undefined}
}

const ApiError: React.FC<ApiErrorProps> = ({ error, values }) => (
  <Translate id={`error.api.${error.toLowerCase()}`} defaultMessage="error.api.unexpected" values={values} />
)

export default ApiError
