import { AppState } from '../redux/reducers'
import { Account, CalendarInfoMessage } from '../redux/envReducer'
import { createSelector } from 'reselect'

export const getAccount = (state: AppState): Account | null => state.env.account

export const getAccountId = createSelector(
  getAccount,
  (account: Account | null): string | null => {
    if (account && account.id) {
      return account.id
    }
  
    return null
  }
)

export const getAccountName = createSelector(
  getAccount,
  (account: Account | null): string => {
    if (account && account.name) {
      return account.name
    }
  
    return ''
  }
)

export const getAccountEmail = createSelector(
  getAccount,
  (account: Account | null): string => {
    if (account && account.email) {
      return account.email
    }
  
    return ''
  }
)

export const getAccountLanguages = createSelector(
  getAccount,
  (account: Account | null): string[] => {
    if (account && account.languages) {
      return account.languages
    }
  
    return []
  }
)

export const getTimeFrameAlmostSoldOutThreshold = createSelector(
  getAccount,
  (account: Account | null): number => {
    if (account && account.timeFrameAlmostSoldOutThreshold) {
      return account.timeFrameAlmostSoldOutThreshold
    }
  
    return 20;
  }
)

export const getCalendarInfoMessage = createSelector(
  getAccount,
  (account: Account | null): CalendarInfoMessage | undefined => {
    if (account && account.calendarInfoMessage) {
      return account.calendarInfoMessage
    }
  
    return undefined;
  }
)

export const getLocale = (state: AppState): string => state.env.locale
