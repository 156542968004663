const openedHours = {
    "2022-07-01": "09:30-17:00",
    "2022-07-02": "09:30-17:00",
    "2022-07-03": "09:30-17:00",
    "2022-07-04": "09:30-18:00",
    "2022-07-05": "09:30-18:00",
    "2022-07-06": "09:30-18:00",
    "2022-07-07": "09:30-18:00",
    "2022-07-08": "09:30-18:00",
    "2022-07-09": "09:30-18:00",
    "2022-07-10": "09:30-18:00",
    "2022-07-11": "09:30-18:00",
    "2022-07-12": "09:30-18:00",
    "2022-07-13": "09:30-18:00",
    "2022-07-14": "09:30-18:00",
    "2022-07-15": "09:30-18:00",
    "2022-07-16": "09:30-18:00",
    "2022-07-17": "09:30-18:00",
    "2022-07-18": "09:30-18:00",
    "2022-07-19": "09:30-18:00",
    "2022-07-20": "09:30-18:00",
    "2022-07-21": "09:30-18:00",
    "2022-07-22": "09:30-18:00",
    "2022-07-23": "09:30-19:30",
    "2022-07-24": "09:30-19:30",
    "2022-07-25": "09:30-19:30",
    "2022-07-26": "09:30-19:30",
    "2022-07-27": "09:30-19:30",
    "2022-07-28": "09:30-19:30",
    "2022-07-29": "09:30-17:00",
    "2022-07-30": "09:30-17:00",
    "2022-07-31": "09:30-17:00",
    "2022-08-01": "09:30-19:30",
    "2022-08-02": "09:30-19:30",
    "2022-08-03": "09:30-19:30",
    "2022-08-04": "09:30-19:30",
    "2022-08-05": "09:30-19:30",
    "2022-08-06": "09:30-19:30",
    "2022-08-07": "09:30-19:30",
    "2022-08-08": "09:30-19:30",
    "2022-08-09": "09:30-19:30",
    "2022-08-10": "09:30-19:30",
    "2022-08-11": "09:30-19:30",
    "2022-08-12": "09:30-19:30",
    "2022-08-13": "09:30-19:30",
    "2022-08-14": "09:30-19:30",
    "2022-08-15": "09:30-19:30",
    "2022-08-16": "09:30-19:30",
    "2022-08-17": "09:30-19:30",
    "2022-08-18": "09:30-19:30",
    "2022-08-19": "09:30-17:00",
    "2022-08-20": "09:30-17:00",
    "2022-08-21": "09:30-17:00",
    "2022-08-22": "09:30-19:30",
    "2022-08-23": "09:30-19:30",
    "2022-08-24": "09:30-19:30",
    "2022-08-25": "09:30-19:30",
    "2022-08-26": "09:30-19:30",
    "2022-08-27": "09:30-19:30",
    "2022-08-28": "09:30-19:30",
    "2022-08-29": "09:30-18:00",
    "2022-08-30": "09:30-18:00",
    "2022-08-31": "09:30-18:00",
    "2022-09-01": "09:30-18:00",
    "2022-09-02": "09:30-18:00",
    "2022-09-03": "09:30-18:00",
    "2022-09-04": "09:30-18:00"
}

export default openedHours;