import { AxiosPromise } from 'axios'
import { get } from '.'
import { Product } from './productApi'

export interface BasketInfoLine {
  id: string
  icon: string
  description: string
}

export interface Page {
  id: string
  description: string
  exposition_id: string
  locale: string
  products: Product[]
  slug: string
  title: string
  type: string
  activities: string[]
  checkout_warning_message: string
  basketInfoLines: BasketInfoLine[]
}

export const getPage = (accountId: string, slug: string): AxiosPromise<Page> =>
  get({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/pages/slug${slug}`,
  })
