import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { injectIntl, IntlShape } from 'react-intl'
import { connect } from 'react-redux'
import BaseLoader from '../loaders/Loader'
import { ThunkDispatch } from '../../redux/types'
import { updateCart } from '../../actions/orderActions'
import { AppState } from '../../redux/reducers'
import { getOrderProducts } from '../../selectors/orderSelectors'
import { OrderProduct } from '../../api/orderApi'
import styled from 'styled-components'
import apiErrorCodes from '../../constants/apiErrorCodes'
import ApiError from '../errors/ApiError'
import { getAccount } from '../../selectors/envSelectors'
import { Account } from '../../redux/envReducer'
import { Col, Row, Button, Alert, Center, AlertType, FormGroup, Translate } from '@jstack/libema-design-system'

const CheckoutButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.margin.lg};
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

const Loader = styled(BaseLoader)`
  margin-right: ${({ theme }) => theme.margin.md};
`

interface CheckoutButtonProps extends RouteComponentProps {
  intl: IntlShape
  orderProducts: OrderProduct[]
  updateCart: (lock?: boolean) => Promise<void>
  account: Account | null
}

interface CheckoutButtonState {
  error?: string
  isSubmitting: boolean
}

class CheckoutButton extends React.Component<CheckoutButtonProps, CheckoutButtonState> {
  constructor(props: CheckoutButtonProps) {
    super(props)
    this.state = {
      error: undefined,
      isSubmitting: false,
    }
  }

  handleCheckout = () => {
    this.setState({ error: undefined, isSubmitting: true })
    this.props
      .updateCart(true)
      .then(() => {
        this.props.history.push(this.props.intl.formatMessage({ id: 'route.checkout' }))
      })
      .catch((err) => {
        if (typeof err.response !== 'undefined') {
          this.setState({ isSubmitting: false, error: err.response?.data?.code || apiErrorCodes.UNEXPECTED })
        } else {
          this.setState({ isSubmitting: false, error: apiErrorCodes.UNEXPECTED })
        }
      })
  }

  render = () => (
    <Row>
      <Col>
        <CheckoutButtonContainer>
          {typeof this.state.error !== 'undefined' && (
            <FormGroup>
              <Alert type={AlertType.ERROR}>
                <ApiError error={this.state.error} values={{ phoneNumber: this.props.account?.phone_number }} />
              </Alert>
            </FormGroup>
          )}
          <Center>
            <Button
              disabled={this.state.isSubmitting === true || this.props.orderProducts.length < 1}
              onClick={this.handleCheckout}
              data-class="checkout-button"
            >
              {this.state.isSubmitting && <Loader />}
              <Translate id="button.checkout_next" />
            </Button>
          </Center>
        </CheckoutButtonContainer>
      </Col>
    </Row>
  )
}

const mapState = (state: AppState) => ({
  orderProducts: getOrderProducts(state),
  account: getAccount(state),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  updateCart: (lock?: boolean) => dispatch(updateCart(lock)),
})

export default connect(mapState, mapDispatch)(withRouter(injectIntl(CheckoutButton)))
