import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { AppState } from '../../redux/reducers'
import { Row, Col, Rect, getCalendarDaySize } from '@jstack/libema-design-system'

const Day = styled(Rect)`
  float: left;
  border: 2.5px solid #fff;
`

const EmptyDay = styled(Day)`
  background: #fff;
`

const SkeletonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`;

const CalendarSkeleton: React.FC<{ device: string }> = ({ device }) => {
  const size = getCalendarDaySize(device);
  const width = '14.285714286%'
  return (
    <>
      <Row>
        <Col col={12}>
          <EmptyDay width={width} height={size} margin="0" />
          <EmptyDay width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
          <Day width={width} height={size} margin="0" />
        </Col>
      </Row>
    </>
  )
}

const ProductDatesSkeleton: React.FC<{ device: string }> = ({ device }) => {
  return (
    <SkeletonWrapper>
      <Row>
        <Col col={12}>
          <CalendarSkeleton device={device} />
        </Col>
      </Row>
    </SkeletonWrapper>
  );
};

const mapState = (state: AppState) => ({
  device: state.env.device
})

export default connect(mapState)(ProductDatesSkeleton)
