import { AxiosPromise } from 'axios'
import { get } from '.'

export interface ProductActivePeriod {
  from: string
  until: string
}

interface ProductArticle {
  description?: string
  shortDescription?: string
}

export interface Product {
  id: string
  image: {}
  name: string
  description: string
  price: number
  from_price?: number
  shortDescription: string
  type: string
  voucher?: string
  activePeriods?: ProductActivePeriod[]
  max_quantity: number
  min_quantity: number
  article: ProductArticle
}

export interface Timeframe {
  availability: number
  from: string
  id: string
  type: string
  until: string
  controlType?: 'person' | 'group',
  maximum?: number,
  maximumPerGroup?: number
}

export const getProducts = (
  accountId: string,
  params: object = {}
): AxiosPromise<Product[]> =>
  get({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/products`,
    params
  })

export const getExpositionProducts = (accountId: string, expositionId: string, params: object = {}): AxiosPromise<{ items: Product[] }> =>
  get({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/expositions/${expositionId}/products`,
    params,
  })

export const getTimeframes = (
  accountId: string,
  params: object = {}
): AxiosPromise<Timeframe[]> =>
  get({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/timeframes`,
    params
  })
