import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { XIcon } from '@heroicons/react/outline'
import { FormattedMessage } from 'react-intl'
import { Account } from '../../redux/envReducer'
import breakpoints from '../../breakpoints'

type HeaderAlertProps = {
  alert: Account['headerAlert']
  locale: string
}

const HeaderAlertStyled = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: ${({ theme }) => theme.color.warning};

  @media only screen and (${breakpoints.device.md}) {
    padding: 8px 24px;
  }
`

const AlertMessage = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  > div {
    text-align: center;
  }
`

const CloseIcon = styled(XIcon)`
  width: 1.5rem;
  height: 1.5rem;
`

export const isActiveByDates = (currentDate: Date, startDate?: string, endDate?: string) => {
  if (!startDate && !endDate) {
    return true
  }

  const currentDateTime = currentDate.getTime()
  const startDateTime = new Date(startDate || '').getTime()
  const endDateTime = new Date(endDate || '').getTime()

  if (startDate && endDate) {
    return currentDateTime >= startDateTime && currentDateTime <= endDateTime
  }
  if (startDate) {
    return currentDateTime >= startDateTime
  }
  if (endDate) {
    return currentDateTime <= endDateTime
  }
  return false
}

const HeaderAlert: FC<HeaderAlertProps> = ({ alert, locale }) => {
  const [showAlert, setShowAlert] = useState(Boolean(alert?.text))

  const isActive = useMemo(() => isActiveByDates(new Date(), alert?.startDate, alert?.endDate), [alert?.startDate, alert?.endDate])

  const closeAlert = () => {
    setShowAlert(false)
  }

  if (!showAlert || !isActive) {
    return null
  }

  return (
    <HeaderAlertStyled role="alert">
      <AlertMessage>
        <div>
          <FormattedMessage
            id={alert?.text}
            values={{
              b: (chunks: string[]) => <b>{`${chunks} `}</b>,
              a: (chunks: string[]) => (
                <a href={alert?.link[locale]} target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
            }}
          />
        </div>
      </AlertMessage>
      <button onClick={closeAlert}>
        <CloseIcon />
      </button>
    </HeaderAlertStyled>
  )
}

export default HeaderAlert
