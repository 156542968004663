import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import styled from 'styled-components'
import { darken } from 'polished'
import { deviceUtils, Container, H1, Button, SubTitle, Translate, RTEContent } from '@jstack/libema-design-system'

type Props = {
  intl: IntlShape
  title: string
  subtitle: string
  buttonUrl: string
  button: string
  imageUrl: string
}

const CardBannerWrapper = styled.section`
  position: relative;
  z-index: 1;
  ${Container} {
    display: flex;
    flex-direction: column;
  }
  background-color: ${({ theme }) => theme.color.neutralLight};
  margin-top: ${({ theme }) => theme.margin.lg};
  padding: ${({ theme }) => `${theme.padding.md}`};

  @media ${deviceUtils.DEVICE_MD_UP} {
    ${Container} {
      flex-direction: row;
    }
  }
`

const TextCard = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  padding-top: ${({ theme }) => theme.padding.md};

  ${H1},
  ${SubTitle} {
    color: #fff;
  }

  @media ${deviceUtils.DEVICE_MD_UP} {
    padding-left: ${({ theme }) => theme.padding.lg};
    padding-bottom: ${({ theme }) => theme.padding.md};
  }
`
const MediaCard = styled.div`
  flex-basis: 50%;
  min-height: 400px;

  @media ${deviceUtils.DEVICE_MD_UP} {
    min-height: 300px;
  }
`

const Backgrounds = styled.div`
  display: flex;
  flex-direction: column;
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  @media ${deviceUtils.DEVICE_MD_UP} {
    flex-direction: row;
  }
`

const MediaBackground = styled.div<{ $imageUrl: string }>`
  flex: 1;
  background-image: url('${(props) => props.$imageUrl}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
const TextBackground = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.color.brown};
`

const ButtonActivities = styled(Button)`
  background-color: ${({ theme }) => theme.color.neutralMedium};
  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.color.neutralMedium)};
  }
`

const CardBanner = ({ intl, title, subtitle, buttonUrl, button, imageUrl }: Props) => {
  return (
    <CardBannerWrapper>
      <Backgrounds>
        <MediaBackground $imageUrl={imageUrl} />
        <TextBackground />
      </Backgrounds>
      <Container>
        <MediaCard />
        <TextCard>
          <div>
            <H1>
              <Translate id={title} />
            </H1>
            <SubTitle>
              <RTEContent dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: subtitle }) }} />
            </SubTitle>
          </div>
          <div>
            <a href={intl.formatMessage({ id: buttonUrl })}>
              <ButtonActivities>
                <Translate id={button} />
              </ButtonActivities>
            </a>
          </div>
        </TextCard>
      </Container>
    </CardBannerWrapper>
  )
}

export default injectIntl(CardBanner)
