import { AppState } from '../redux/reducers'
import { Product, ProductActivePeriod } from '../api/productApi'
import { getTimeframe } from './timeframeSelectors'
import moment from 'moment'

export const getProducts = (state: AppState): { [id: string]: Product } => state.products

/**
 * Retrieves products & filters them to the given type and timeframe
 * @param state Application State: AppState
 * @param type Type to filter towards: string
 * @return Array of Products which match the given type
 */
export const getProductsByType = (state: AppState, type: string, timeframeId?: string): Product[] => {
  let products = Object.values(state.products).filter((p) => p.type === type)

  if (timeframeId) {
    const timeframe = getTimeframe(state, timeframeId)

    products = products.filter((product) => {
      if (!Array.isArray(product.activePeriods) || product.activePeriods.length === 0) {
        return true
      }

      const timeframeFrom = moment(timeframe?.from)
      const timeframeUntil = moment(timeframe?.until)

      const isWithinTimeframe = (period: ProductActivePeriod) =>
        moment(period.from).isSameOrBefore(timeframeFrom) && moment(period.until).isSameOrAfter(timeframeUntil)

      return !!product.activePeriods.find(isWithinTimeframe)
    })
  }

  return products
}
