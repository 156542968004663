import { DefaultTheme } from 'styled-components'
import { lighten } from 'polished'

export interface CustomTheme {
  button_border_radius?: string
  color_neutral_dark?: string
  color_neutral_medium?: string
  color_neutral_light?: string
  color_primary?: string
  color_secondary?: string
  // color_tertiary?: string
  font_default?: string
  text_color_default?: string
  text_color_muted?: string
  logo?: string
  background_content?: string
}

type BackgroundColors = {
  info: string
  error: string
  success: string
  warning: string
  content: string
}

type Button = {
  borderRadius: string
}

export type Theme = DefaultTheme & {
  backgroundColor: BackgroundColors
  button: Button
}

export const getTheme = (customTheme: CustomTheme = {}): Theme => ({
  color: {
    dark: '#292b2c',
    neutralDark: customTheme.color_neutral_dark || '#a48a76', // Dark Sand
    neutralMedium: customTheme.color_neutral_medium || '#a08b79',
    neutralLight: customTheme.color_neutral_light || '#f6f1e6', // Sand
    primary: customTheme.color_primary || '#e84d0e', // Orange
    secondary: customTheme.color_secondary || '#fcce4c', // Yellow
    // tertiary: customTheme.color_tertiary || '#fcce4c',
    brown: "#AB9681",
    error: '#b31d2b',
    success: '#28a745',
    warning: '#ffc107',
  },
  button: {
    borderRadius: customTheme.button_border_radius || 'none',
  },
  backgroundColor: {
    info: lighten(0.5, '#284ea7'),
    error: lighten(0.4, '#b31d2b'),
    success: lighten(0.4, '#28a745'),
    warning: lighten(0.4, '#ffc107'),
    content: customTheme.background_content || '#EAE1C8',
  },
  font: {
    default: customTheme.font_default || 'sofia-pro, "Century Gothic", sans-serif',
  },
  textColor: {
    default: customTheme.text_color_default || '#292b2c', // Dark (Blackish)
    muted: customTheme.text_color_muted || '#5c6738', // Green
  },
  margin: {
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
  },
  padding: {
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
  },
})
