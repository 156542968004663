import React from 'react'
import styled from 'styled-components'
import { Translate } from '@jstack/libema-design-system'
import { useIntl } from 'react-intl'

const NoTimeframesAlert = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  padding: ${({ theme }) => theme.padding.md};
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

export default ({ expositionId }: { expositionId?: string }) => {
  const intl = useIntl()

  const translationId = `warning.${expositionId}.no_timeframes`
  const messageIdToUse = intl.messages[translationId] ? translationId : 'warning.default.no_timeframes'

  return (
    <NoTimeframesAlert data-class="alert-no-timeframes">
      <Translate id={messageIdToUse} defaultMessage={intl.messages['warning.default.no_timeframes']} />
    </NoTimeframesAlert>
  )
}
