import { Account } from '../redux/envReducer'
import { Product, Timeframe } from '../api/productApi'
import { OrderState } from '../redux/orderReducer'
import { Order, OrderProduct } from '../api/orderApi'
import { set, get } from '../redux/localStorage'
import { ACCOUNT_NAMES } from '../data/accounts'
import { calculateTotalValue } from './analyticsUtils.helpers'

// @TODO: Fix custom events
type ReservationDetailsEvent = {
  event: 'ReserveringDetails'
  tijdsloten: ReservationTimeframe[]
  expositionId?: string
  customer?: {
    email?: string
    phone?: string
  }
}

interface PurchaseEvent {
  event: 'purchase'
  ecommerce: {
    transaction_id: string
    value: number
    currency?: string
    exposition_id?: string
    coupon?: string
    items?: {
      item_id: string
      item_name: string | null
      affiliation: string
      item_category: string
      price: number
      quantity: number
      coupon?: string
    }[]
  }
}

interface ReservationTimeframe {
  vanaf: string
  tot: string
}

interface GoogleAnalyticsEvent {
  event?: string
  [prop: string]: any
}

const TWO_DAYS = 172800

const pushEvent = (event: GoogleAnalyticsEvent): void => {
  // @ts-ignore
  if (typeof window.dataLayer === 'undefined') {
    // @ts-ignore
    window.dataLayer = []
  }
  // @ts-ignore
  window.dataLayer.push(event)
}

const getUniqueTimeframes = (products: OrderProduct[]): ReservationTimeframe[] =>
  products
    .filter((product, i) => {
      const firstMatch = products.find((item) => item.period_from === product.period_from)

      if (typeof firstMatch === 'undefined' || firstMatch.id !== product.id) {
        return false
      }

      return true
    })
    .map((product) => ({
      vanaf: product.period_from ? product.period_from : '',
      tot: product.period_until ? product.period_until : '',
    }))

const orderSuccessful = (account: Account, orderNumber: string, revenue: number, order: OrderState, expositionIds?: string[]) => {
  /*
   * We're using expositionIds as customerType. The marketing team will create
   * a mapping expositionId => customerType on Google Tag Manager using a
   * regex table.
   */
  const expositionIdsFormatted = expositionIds?.join(',')

  const purchaseEvent: PurchaseEvent = {
    event: 'purchase',
    ecommerce: {
      transaction_id: orderNumber,
      value: Number((revenue / 100).toFixed(2)),
      currency: 'EUR',
      exposition_id: expositionIdsFormatted,
    },
  }

  if (typeof order.products !== 'undefined') {
    purchaseEvent.ecommerce.items = order.products.map((item: OrderProduct) => ({
      item_id: item.id,
      item_name: item.name,
      item_category: item.type === 'date_ticket' ? 'Entreeticket' : 'Overig',
      price: item.price,
      quantity: item.quantity,
      affiliation: account?.gtm_affiliation,
    }))
  }

  /*
   * Sometimes a purchaseEvent is trigger twice... We're not able
   * to reproduce this so in order to circumvent this bug check
   * whether it has already been triggered using locale storage.
   */
  if (get(orderNumber) == null) {
    const reservationEvent: ReservationDetailsEvent = {
      event: 'ReserveringDetails',
      tijdsloten: getUniqueTimeframes(order.products),
      expositionId: expositionIdsFormatted,
    }

    if (account?.name === ACCOUNT_NAMES.AVIODROME) {
      reservationEvent.customer = {
        email: order.customer?.email,
      }
    }

    pushEvent(reservationEvent)
    pushEvent(purchaseEvent)

    set(orderNumber, { triggered: true }, TWO_DAYS)
  }

  pushEvent({
    event: 'virtual_page_view',
    pageUrl: window.location.href,
    pageTitle: document.title,
  })
}

const productImpressions = (products: Product[], timeframe: Timeframe) => {
  pushEvent({
    event: 'view_item_list',
    ecommerce: {
      items: products.map((product, idx) => ({
        item_id: product.id,
        item_name: product.name,
        coupon: product?.voucher,
        index: idx,
        price: product.price,
        // Define in which ReCreateX timeframe it was printed to the user.
        item_variant: timeframe.id,
      })),
    },
  })
}

const updateCart = (product: OrderProduct, quantityChanged: number) => {
  if (quantityChanged === 0) {
    return
  }

  const isToAdd = quantityChanged > 0
  const quantity = Math.abs(quantityChanged)
  const event = isToAdd ? 'add_to_cart' : 'remove_from_cart'
  const key = isToAdd ? 'add' : 'remove'

  pushEvent({
    event,
    ecommerce: {
      currency: 'EUR',
      value: product.price * quantity,
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          coupon: product?.voucher,
          item_variant: product.period_id,
          price: product.price,
          quantity,
        },
      ],
    },
  })
}

const removeFromCart = (product: OrderProduct) => {
  updateCart(product, -product.quantity)
}

const checkoutStep1 = (products: OrderProduct[]) => {
  pushEvent({
    event: 'begin_checkout',
    ecommerce: {
      currency: 'EUR',
      value: calculateTotalValue(products),
      items: products.map((product) => ({
        item_id: product.id,
        item_name: product.name,
        coupon: product?.voucher,
        // Define in which ReCreateX timeframe it was printed to the user.
        item_variant: product.period_id,
        price: product.price,
        quantity: product.quantity,
      })),
    },
  })
}

// @TODO: Fix custom events
const checkoutFinish = () => {
  pushEvent({
    event: 'finish_checkout',
  })
}

// @TODO: Fix custom events
const checkoutAcceptTerms = (products: OrderProduct[]) => {
  pushEvent({
    event: 'accept_terms_conditions',
    ecommerce: {
      currencyCode: 'EUR',
      value: calculateTotalValue(products),
      items: products.map((product) => ({
        item_id: product.id,
        item_name: product.name,
        coupon: product?.voucher,
        // Define in which ReCreateX timeframe it was printed to the user.
        item_variant: product.period_id,
        price: product.price,
        quantity: product.quantity,
      })),
    },
  })
}

const checkoutPaymentMethod = (paymentMethod: string, products: OrderProduct[]) => {
  pushEvent({
    event: 'add_payment_info',
    ecommerce: {
      currencyCode: 'EUR',
      value: calculateTotalValue(products),
      payment_type: paymentMethod,
      items: products.map((product) => ({
        item_id: product.id,
        item_name: product.name,
        coupon: product?.voucher,
        // Define in which ReCreateX timeframe it was printed to the user.
        item_variant: product.period_id,
        price: product.price,
        quantity: product.quantity,
      })),
    },
  })
}

const virtualPageView = () => {
  if (typeof window === 'undefined' || !window.location || !document.title) {
    return
  }
  pushEvent({
    event: 'virtual_page_view',
    pageUrl: window.location.href,
    pageTitle: document.title,
  })
}

export default {
  orderSuccessful,
  productImpressions,
  removeFromCart,
  updateCart,
  checkoutStep1,
  checkoutFinish,
  checkoutAcceptTerms,
  checkoutPaymentMethod,
  pushEvent,
  virtualPageView,
}
