import React from 'react'
import { connect } from 'react-redux'
import { Formik, Field, FormikHelpers } from 'formik'
import CheckIcon from '@fortawesome/fontawesome-free/svgs/solid/check.svg'
import { AppState } from '../../redux/reducers'
import { ThunkDispatch } from '../../redux/types'
import Loader from '../loaders/Loader'
import { validateVoucher, removeFromCart } from '../../actions/orderActions'
import { injectIntl, IntlShape } from 'react-intl'
import { getProductsByType } from '../../selectors/productSelectors'
import { getOrderProducts } from '../../selectors/orderSelectors'
import productTypes from '../../constants/productTypes'
import { Product } from '../../api/productApi'
import ProductsForm from './ProductsForm'
import { OrderProduct } from '../../api/orderApi'
import {
  H3,
  Col,
  Row,
  Button,
  InputGroupAppend,
  Form,
  FormGroup,
  TextField,
  Small,
  ErrorMessage,
  InputGroup,
  Input,
  Hr,
  Translate,
} from '@jstack/libema-design-system'

interface VoucherFormValues {
  voucher: string
}

interface VoucherFormErrors {
  voucher?: string
}

interface VoucherFormProps {
  intl: IntlShape
  orderProducts: OrderProduct[]
  remove: (productId: string, timeframeId?: string) => void
  validateVoucher: (params: { voucher: string }) => Promise<{ voucher_status: number }>
  voucherProducts: Product[]
}

interface VoucherFormState {
  vouchers: string[]
}

// const DeleteButton = styled(Button)`
//   padding: ${({ theme }) => theme.padding.md};
//   svg {
//     width: 1em;
//     height: 1em;
//     top: .125em;
//     position: relative;
//   }
// `

class VoucherForm extends React.Component<VoucherFormProps, VoucherFormState> {
  constructor(props: VoucherFormProps) {
    super(props)

    this.state = {
      vouchers: [],
    }
  }

  componentDidMount() {
    const { voucherProducts } = this.props
    if (voucherProducts) {
      const prevVouchers = voucherProducts.map((voucher) => voucher.voucher || '')
      this.setState({
        vouchers: Array.from(new Set(prevVouchers)),
      })
    }
  }

  handleSubmit = (values: VoucherFormValues, formikHelpers: FormikHelpers<VoucherFormValues>) => {
    const {
      intl: { formatMessage },
      voucherProducts,
    } = this.props

    if (voucherProducts.length >= 7) {
      formikHelpers.setSubmitting(false)
      return formikHelpers.setFieldError('voucher', formatMessage({ id: 'error.voucher_maximum_vouchers_reached' }))
    }

    return this.props
      .validateVoucher(values)
      .then((response) => {
        if (response.voucher_status > 0) {
          formikHelpers.setFieldError('voucher', formatMessage({ id: 'error.voucher_redeem_failed' }))
        } else {
          this.setState((prevState) => ({ vouchers: prevState.vouchers.concat(values.voucher) }))
          formikHelpers.resetForm()
        }
        formikHelpers.setSubmitting(false)
      })
      .catch(() => {
        formikHelpers.setFieldError('voucher', formatMessage({ id: 'error.voucher_validation_failed' }))
        formikHelpers.setSubmitting(false)
      })
  }

  getProductCountForVoucher = (voucher: string): number => {
    return this.props.orderProducts.filter((product) => product.voucher === voucher).length
  }

  render = () => (
    <>
      {this.state.vouchers.map((voucher: string) => (
        <Row key={voucher}>
          <Col lg={7} md={12} sm={12}>
            <InputGroup>
              <Input disabled={true} placeholder={voucher} />
              <InputGroupAppend>
                <CheckIcon fill="green" />
              </InputGroupAppend>
            </InputGroup>
          </Col>
          <Col col={12}>
            <ProductsForm
              type={productTypes.VOUCHER}
              products={this.props.voucherProducts.filter((product) => product.voucher === voucher)}
              max={1}
              maxWithinForm={true}
            />
            {this.getProductCountForVoucher(voucher) >= 1 && (
              <Small>
                <Translate id="warning.max_voucher_products_reached" />
              </Small>
            )}
            <Hr />
          </Col>
        </Row>
      ))}
      {this.state.vouchers.length > 0 && (
        <H3>
          <Translate id="title.another_voucher" />
        </H3>
      )}
      <Formik
        initialValues={{
          voucher: '',
        }}
        validate={(values: VoucherFormValues) => {
          const {
            intl: { formatMessage },
          } = this.props

          const errors: VoucherFormErrors = {}
          if (values.voucher.length === 16) {
            const existingVoucher = this.state.vouchers.find((voucher) => voucher === values.voucher)
            if (existingVoucher) {
              errors.voucher = formatMessage({ id: 'error.voucher_already_exists' })
            }
          }
          return errors
        }}
        onSubmit={this.handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Row>
              <Col col={12} md={7}>
                <FormGroup>
                  <Field
                    name="voucher"
                    type="text"
                    component={TextField}
                    placeholder={this.props.intl.formatMessage({ id: 'placeholder.voucher' })}
                  />
                  <ErrorMessage name="voucher" />
                </FormGroup>
              </Col>
              <Col col={12} md={5}>
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                    <>
                      <Loader />
                      &nbsp;
                    </>
                  )}
                  <Translate id="button.voucher_redeem" />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapState = (state: AppState) => ({
  orderProducts: getOrderProducts(state),
  voucherProducts: getProductsByType(state, productTypes.VOUCHER),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  remove: (productId: string, timeframeId?: string) => dispatch(removeFromCart(productId, timeframeId)),
  validateVoucher: (params: { voucher: string }) => dispatch(validateVoucher(params)),
})

export default connect(mapState, mapDispatch)(injectIntl(VoucherForm))
