import moment from 'moment'
import _ from 'lodash'
import { OrderProduct } from '../api/orderApi'
import { CartItem } from '../redux/orderReducer'
import { Timeframe } from '../api/productApi'

const compare = (product: OrderProduct, otherProductId: string, otherTimeframeId?: string): boolean => {
  const idMatch = product.id === otherProductId
  if (idMatch && otherTimeframeId) {
    return product.period_id === otherTimeframeId
  }

  return idMatch
}

const removeFromList = (
  orderProducts: OrderProduct[],
  productId: string,
  timeframeId?: string
  ): OrderProduct[] => {
  return orderProducts
  .filter((item: OrderProduct) => !compare(item, productId, timeframeId))
}

const mergeProductsWithCartItems = (products: OrderProduct[], cartItems: CartItem[]): OrderProduct[] => {
  let items = [...products]
  if (cartItems && cartItems.length) {
    items = items.map((product: OrderProduct) => {
      const cartItemWithLockTicket = cartItems.find((item: CartItem) => {
        if (!item.entries) {
          return false
        }
        return item.entries[0].priceGroupId === product.id && product.period_id === item.expositionPeriodId
      })

      if (cartItemWithLockTicket) {
        return {
          ...product,
          lock: cartItemWithLockTicket.lockTicket,
        }
      }

      return product
    })
  }

  return items.filter((item: OrderProduct) => item.quantity > 0)
}

/**
 * This function updates an array of OrderProduct's with a given OrderProduct.
 * - It adds it if it wasn't part of the collection yet,
 * - It replaces an existing one if the quantity is positive
 * - It removes an existing one if the quantity is not positive
 */
const updateCollection = (collection: OrderProduct[], product: OrderProduct): OrderProduct[] => {
  const i = collection.findIndex((p: OrderProduct) => {
    const idMatch = p.id === product.id

    if (idMatch && product.period_id) {
      return p.period_id === product.period_id
    }
    return idMatch
  })
  const products = [...collection]
  if (i !== -1) {
    if (product.quantity < 1) {
      delete products[i]
    } else {
      products[i] = product
    }
  } else if (product.quantity > 0) {
    products.push(product)
  }

  return products.filter((p: OrderProduct) => typeof p !== 'undefined' && p !== null)
}

export const getVoucherProductId = (id: string) => id.split('#')[0]

/**
 * This calculates the max available tickets for a timeframe.
 * There's a global tickets limit, but also a maximum amount of
 * available tickets for a specific timeframe (timeframes might be nearly full).
 * See related unit tests for different situations that are handled here.
 */
export const calculateMaxProducts = (
  current: number,
  maxTimeframeTickets: number,
  maxTickets: number,
  totalTimeframeTickets: number,
  totalTickets: number,
  maxTicketsProduct?: number) => {
  if (totalTickets >= maxTickets) {
    return current
  }

  if (maxTicketsProduct && maxTickets > maxTicketsProduct && totalTimeframeTickets >= maxTicketsProduct) {
    return maxTicketsProduct;
  }

  if (totalTimeframeTickets >= maxTimeframeTickets) {
    return current
  }

  if (maxTimeframeTickets <= maxTickets) {
    return maxTimeframeTickets
  }

  return maxTickets
}

const getTimeframeName = (timeframe: Timeframe) => `${moment(timeframe.from).format('D MMMM YYYY, H:mm')} - ${moment(timeframe.until).format('H:mm')}`

const isSoldOut = (timeframe: Timeframe) => {
  const soldOut = timeframe.availability <= 0
  return soldOut
}

const isAlmostSoldOut = (timeframe: Timeframe, threshold: number = 20) => {
  const { availability, controlType } = timeframe

  if (isSoldOut(timeframe) || controlType === 'group') {
    return false
  }

  return availability <= threshold;
}

const totalTicketsByExposition = (orderProducts: OrderProduct[], expositionId: string) => _(orderProducts)
  .map((order) => ({
    ...order,
    exposition_id: order.exposition_id?.toLowerCase()
  }))
  .filter({ exposition_id: expositionId.toLowerCase() })
  .groupBy('id')
  .map((orders) => _.sumBy(orders, 'quantity'))
  .sum();

export default {
  compare,
  getTimeframeName,
  mergeProductsWithCartItems,
  removeFromList,
  updateCollection,
  isSoldOut,
  isAlmostSoldOut,
  totalTicketsByExposition,
}
