const SHOP_ENTRY = 'shop_entry'

const setShopEntry = (route: string): void => {
  if (typeof window === 'undefined') {
    return
  }

  window.localStorage.setItem(SHOP_ENTRY, route)
}

const getShopEntry = (): string => {
  if (typeof window === 'undefined') {
    return '/'
  }
  const shopEntry = window.localStorage.getItem(SHOP_ENTRY)
  if (shopEntry === null) {
    return '/'
  }
  return shopEntry
}

export default {
  getShopEntry,
  setShopEntry
}
