import { SET_EXPOSITION } from '../actions/expositionActions';
import { Exposition } from '../api/expositionApi';

interface SetExpositionAction {
  type: typeof SET_EXPOSITION;
  exposition: Exposition;
}

export type ExpositionState = Exposition | null;

export const initialState: ExpositionState = null;

export default (state: ExpositionState = initialState, action: SetExpositionAction) => {
  switch (action.type) {
    case SET_EXPOSITION: {
      return {
        ...action.exposition
      }
    }
    default: {
      return state;
    }
  }
};
