import { createGlobalStyle } from 'styled-components'

const Theme = createGlobalStyle`

   body {
      background: #000 url("/lightsafari/website-bg.jpg") !important;
      color: white;
   }

   header img {
      height: 48px!important;
   }

   // Payment ideal bank options color
   div[class*="-option"] {
      color: black!important
   }

   // Payment ideal bank options color
   div[class^="PaymentMethodsField__PaymentMethodWrapper"] {
      background: #2b2b2b;
   }

   label[for="terms"] a,
   form small {
      color: white!important;
   }

   [data-class="checkout-button"] {
      background: linear-gradient(180deg, #FFDE00 0%, #FC7D0A 100%)!important;
      color: black;

      &:disabled {
         background: gray!important;
      }

      &:hover {
         color: black;
      }
   }
   [data-class="change-order-button"] {
      padding-top: 16px;
      color: white;
   }

   a {
      color: white!important;
   }
   

   form.checkout button {
      background: linear-gradient(180deg, #FFDE00 0%, #FC7D0A 100%)!important;
      color: black;

      &:disabled {
         background: gray!important;
      }

      &:hover {
         color: black;
      }
   }

   [data-class="product-skeleton"] {
      background: #2b2b2b;
   }

   [data-class="product-row"]{
      background: #2b2b2b!important;
      border: 1px solid #00fd5a;
      color: white;

      svg {
         fill: white;
      }

      button {
         background: linear-gradient(180deg, #FFDE00 0%, #FC7D0A 100%)!important;
         color: black;

         &:hover {
            background: linear-gradient(360deg, #FFDE00 0%, #FC7D0A 100%)!important;           
         }
      }
   }

   div[type="warning"] {
      background: linear-gradient(180deg, #FFDE00 0%, #FC7D0A 100%)!important;;
      color: black!important;
   }

   div[type="error"] {
         background: rgb(211, 47, 47)!important;
         color: white!important;
   }

   [data-class="timeframe-selector"] {
      background-color: #2b2b2b!important;
      color: white;
      button {
         background: linear-gradient(180deg, #FFDE00 0%, #FC7D0A 100%)!important;
         color: black;
      
         &:hover {
            color: black;
         }
      }
   }

   [data-class="alert-no-timeframes"] {
      background: linear-gradient(180deg, #FFDE00 0%, #FC7D0A 100%)!important;
      color: black;
   }

   [data-class="price-block"] {
      color: black;
   }

   // Calendar

   [data-class="date-picker"] {
      color: white;
   }

   .DayPicker,
   .CalendarMonthGrid,
   .CalendarMonth {
      background: black!important;
   }

   .CalendarDay__default {
      border: 5px solid black!important;
   }
   .CalendarDay__blocked_calendar {
      background: repeating-linear-gradient( 45deg,#000,#000 8px,#6b6b6b 8px,#6b6b6b 16px )!important;
      color: white!important;
   }

   .CalendarDay__today {
      box-shadow: inset 0 0 0 3px #FF7B11!important;
      color: white!important;

      hover: {
         box-shadow: inset 0 0 0 3px #00fd5a!important;
         color: black!important;
      }
   }

   .CalendarDay__selected {
      background: black!important;
      box-shadow: inset 0 0 0 3px #ffde00!important;
      color: white!important;

      hover: {
         box-shadow: inset 0 0 0 3px #FC7D0A!important;
      }
   }
   .CalendarDay__highlighted_calendar {
      background: repeating-linear-gradient( 45deg,#fff,#fff 8px,#f8d7da 8px,#f8d7da 16px )!important;
      color: black!important;
   }

   .CalendarMonth_caption,
   .DayPicker_weekHeader_li {
      color: white!important;
   }

   //Legend for today state
   div[class^="DatePicker__LegendItem"] .CalendarDay__today {
      background: black!important;
    }


   // Confirmation CTA Card
   div[class^="CardBanner__TextBackground-ticketshop"] {
      background: linear-gradient(180deg, #0043D8 0%, #009DFE 100%);!important;

   }

   a[class*="OrderConfirmationlightsafari__DesktopDownloadButton-ticketshop"] {
      background: linear-gradient(180deg, #FFDE00 0%, #FC7D0A 100%);!important;
      color: black!important;
      &:hover {
         color: black;
         background: linear-gradient(360deg, #FFDE00 0%, #FC7D0A 100%);!important;  
      }
  }
   a[class*="OrderConfirmationlightsafari__MobileDownloadButton-ticketshop"] {
      background: linear-gradient(180deg, #FFDE00 0%, #FC7D0A 100%);!important;
      color: black!important;
      &:hover {
         color: black;
         background: linear-gradient(360deg, #FFDE00 0%, #FC7D0A 100%);!important;  
      }
  }

  button[class*="CardBanner__ButtonActivities-ticketshop"] {
         background: linear-gradient(180deg, #FFDE00 0%, #FC7D0A 100%);!important;
         color: black!important;
      
         &:hover {
            color: black;
            background: linear-gradient(360deg, #FFDE00 0%, #FC7D0A 100%);!important;  
         }
      }
   }


  

   
`

export default Theme
