import React from 'react'
import { Rect } from '@jstack/libema-design-system'

const ProductsFormSkelecton: React.FC = () => (
  <>
    <Rect data-class="product-skeleton" height={124} margin="0 0 8px" />
    <Rect data-class="product-skeleton" height={124} margin="0 0 8px" />
    <Rect data-class="product-skeleton" height={124} margin="0 0 8px" />
    <Rect data-class="product-skeleton" height={124} margin="0 0 8px" />
    <Rect data-class="product-skeleton" height={124} margin="0 0 8px" />
  </>
)

export default ProductsFormSkelecton
