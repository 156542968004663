import React from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { ThemeProvider } from 'styled-components'
import { BaseCSS } from 'styled-bootstrap-grid'
import moment from 'moment'
import { getTheme, CustomTheme } from '../../theme'
import GlobalStyle from '../layout/GlobalStyle'
import translations from '../../translations'
import { AppState } from '../../redux/reducers'
import { Account } from '../../redux/envReducer'
import Helmet from 'react-helmet'
import { getAccountName } from '../../selectors/envSelectors'
import { capitalize } from '../../utils/stringUtils'
import * as themes from '../../themes'

interface RootProviderProps {
  account: Account | null
  accountName: string
  children: React.ReactElement[] | null
  locale: string
  theme: CustomTheme
}

export const getThemeStyle = (accountName: string) => themes[accountName] || null

const RootProvider: React.FC<RootProviderProps> = ({ account, accountName, children, locale, theme }) => {
  let customTheme
  if (theme) {
    customTheme = theme
  } else if (account) {
    customTheme = account.theme
  }

  const ThemeStyle = getThemeStyle(accountName)

  moment.locale(locale)

  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <Helmet titleTemplate={`%s - ${capitalize(accountName)}`}>
        <html lang={locale} />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="192x192" href={`https://d2qksnoxbl01xg.cloudfront.net/${accountName}/favicon-192x192.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`https://d2qksnoxbl01xg.cloudfront.net/${accountName}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="96x96" href={`https://d2qksnoxbl01xg.cloudfront.net/${accountName}/favicon-96x96.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`https://d2qksnoxbl01xg.cloudfront.net/${accountName}/favicon-16x16.png`} />
      </Helmet>
      <ThemeProvider theme={getTheme(customTheme)}>
        <>
          <BaseCSS />
          <GlobalStyle />
          {ThemeStyle != null && <ThemeStyle />}
          {children}
        </>
      </ThemeProvider>
    </IntlProvider>
  )
}

const mapState = (state: AppState) => ({
  account: state.env.account,
  accountName: getAccountName(state),
  locale: state.env.locale,
})

export default connect(mapState)(RootProvider)
