import { OrderProduct } from '../api/orderApi'

export const calculateTotalValue = (products: OrderProduct[]) => {
  const totalValue = products.reduce((total, product) => {
    const productRevenue = product.price * product.quantity
    return total + productRevenue
  }, 0)

  return totalValue
}
