import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { AppState } from '../../redux/reducers'
import { getLocale, getAccount } from '../../selectors/envSelectors'
import { Account } from '../../redux/envReducer'
import { Col, Row, Container, Translate, deviceUtils } from '@jstack/libema-design-system'

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.color.neutralDark};
  padding: ${({ theme }) => theme.padding.lg};
  color: #fff;
`

const FooterLink = styled.a`
  color: #fff;
  display: block;
  margin-right: ${({ theme }) => theme.margin.md};
  text-align: center;

  @media ${deviceUtils.DEVICE_LG_UP} {
    text-align: left;
    display: inline-block;
  }

  :hover,
  :visited {
    color: #fff;
  }
`

const RightCol = styled(Col)`
  text-align: center;
  display: block;
  @media ${deviceUtils.DEVICE_LG_UP} {
    display: inline-block;
    text-align: right;
  }
`

interface FooterProps {
  account: Account | null
  locale: string
}

const Footer: React.FC<FooterProps> = ({ account, locale }) => (
  <FooterWrapper>
    <Container>
      <Row>
        <Col col={12} lg={6}>
          <FooterLink href="/cookies">
            Cookies
          </FooterLink>
          <FooterLink href={account?.disclaimer[locale]} target="_blank">
            <Translate id="button.disclaimer" />
          </FooterLink>
          <FooterLink href={account?.privacystatement[locale]} target="_blank">
            <Translate id="button.privacy_statement" />
          </FooterLink>
          <FooterLink href={account?.parkregulations[locale]} target="_blank">
            <Translate id="button.park_regulations" />
          </FooterLink>
        </Col>
        <RightCol col={12} lg={6}>
          <Translate id="title.libema_footer" values={{ account: account?.title }} />&nbsp;
          <img alt="Libéma" src="https://d33b12c77av9bg.cloudfront.net/cache/full_size/libemawhitesmalle.png" />
        </RightCol>
      </Row>
    </Container>
  </FooterWrapper>
)

const mapState = (state: AppState) => ({
  account: getAccount(state),
  locale: getLocale(state)
})

export default connect(mapState)(Footer)
