import config from '../../package.json';

/**
 * Sets an item to the localStorage with a TTL
 * since localStorage itself does not support a TTL.
 * 
 * @param key The name of the item in localstorage
 * @param data The data to be stored
 * @param ttl The time we see the data as valid, in seconds
 */
export const set = (key: string, data: any, ttl: number) => {
  const item = {
    data,
    expiresAt: Date.now() + (ttl * 1000)
  };

  localStorage.setItem(key, JSON.stringify(item));
};

/**
 * Fetches an item from the localstorage.
 * It only returns the data if the TTL has not expired yet.
 * 
 * @param key The name of the item in localstorage
 */
export const get = (key: string) => {
  const itemString = localStorage.getItem(key);
  if (!itemString) {
    return null;
  }
  const item = JSON.parse(itemString);
  if (Date.now() > item.expiresAt) {
    localStorage.removeItem(key);
    return null;
  }

  return item.data;
};

export const clear = (key: string) => localStorage.removeItem(key);

export const STATE_KEY = `libema-state-${config.version}`;

export const loadState = () => {
  try {
    const serializedState = get(STATE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return serializedState;
  } catch (err) {
    return undefined;
  }
}; 

export const saveState = (state: object) => {
  try {
    set(STATE_KEY, state, 900);
  } catch {
    // ignore write errors
  }
};
