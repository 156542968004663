import moment from 'moment'
import { createSelector } from 'reselect'
import { AppState } from '../redux/reducers'
import { Timeframe } from '../api/productApi'

export const getTimeframes = (state: AppState): {[id: string]: Timeframe} => state.timeframes

export const hasTimeframes = (state: AppState): boolean => Object.keys(state.timeframes).length > 0

export const getTimeframe = (state: AppState, id?: string): Timeframe =>
  state.timeframes[id || 'undefined']

export const getTimeframesForDate = (state: AppState, date: string): Timeframe[] =>
  Object
    .values(state.timeframes)
    .filter((period: Timeframe) => period.from.startsWith(date))

export const getAvailableTimeframesForDate = createSelector(
  getTimeframesForDate,
  (res: Timeframe[]) => {
    const inFiveMinutes = moment().add(5, 'minutes')
    return res.filter((timeframe) => moment(timeframe.until) > inFiveMinutes)
  }
)
