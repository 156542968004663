import React from 'react'
import Base from './Base'
import styled from 'styled-components'
import { CustomTheme } from '../theme'
import { Account } from '../redux/envReducer'

const Main = styled.div`
  margin: 50px 0;
  min-height: 400px;
`

interface CookieProps {
  account: Account
  isIE: boolean
  theme: CustomTheme
}

class CookiePage extends React.Component<CookieProps, {}> {
  private cookiebotRef = React.createRef<HTMLDivElement>()
  static async getInitialProps(props: CookieProps) {
    return {
      account: props.account,
      isIE: props.isIE,
      theme: props.theme,
    }
  }
  
  componentDidMount() {
    const script = document.createElement('script')

    script.id = 'CookieDeclaration'
    script.src = `https://consent.cookiebot.com/${this.props.account.cookiebot_id}/cd.js`
    script.async = true

    // @ts-ignore
    this.cookiebotRef.current.appendChild(script)
  }

  componentWillUnmount() {
    // @ts-ignore
    this.cookiebotRef.current.removeChild(script)
  }

  render = () => {
    const { theme, isIE } = this.props
    return (
      <Base theme={theme} isIE={isIE}>
        <Main>
          <div ref={this.cookiebotRef} />
        </Main>
      </Base>
    )
  }
}

export default CookiePage
