import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'
// import { saveState, loadState } from './localStorage'

const configureStore = (preloadedState: object) => {
  const middleware = [
    thunkMiddleware,
  ]

  const store = createStore(
    rootReducer,
    { ...preloadedState },
    compose(
      applyMiddleware(...middleware),
      typeof window !== 'undefined'
        && (process.env.NODE_ENV !== 'production')
        && (window as any).__REDUX_DEVTOOLS_EXTENSION__
          ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
          : (f: any) => f
    )
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }

  // store.subscribe(() => {
  //   const currentState = store.getState()
  //   saveState({
  //     order: currentState.order
  //   })
  // })

  return store
}

export default configureStore
