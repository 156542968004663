import React, { useState } from 'react'
import { Button, Center, deviceUtils, Translate } from '@jstack/libema-design-system'
import styled from 'styled-components'
import { Theme } from '../../theme'

type AgeConsentProps = {
  imageMobile: string
  imageDesktop: string
  setAgeAllowed: (ageAllowed: boolean) => void
}

type ImageContainerProps = {
  imageMobile: string
  imageDesktop: string
}

type AgeConsentContentProps = {
  theme: Theme
}

const ImageWrapper = styled.div`
  max-width: 1116px; // width of original image
  margin: 0 auto;
`

const ImageContainer = styled.div<ImageContainerProps>`
  background-image: url(${({ imageMobile }) => imageMobile});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-top: 68%;

  @media ${deviceUtils.DEVICE_MD_UP} {
    background-image: url(${({ imageDesktop }) => imageDesktop});
    padding-top: 43%;
  }
`

const MoreInfo = styled.div`
  display: flex;
  justify-content: center;

  @media ${deviceUtils.DEVICE_MD_UP} {
    justify-content: right;
  }

  img {
    margin-left: ${({ theme }) => theme.margin.md};
  }
`

const AgeConsentContent = styled.div<AgeConsentContentProps>`
  background-color: ${({ theme }) => theme.backgroundColor.content};
  padding: ${({ theme }) => theme.padding.md};

  h3,
  p {
    margin-bottom: ${({ theme }) => theme.margin.sm};
  }

  ${Button} {
    font-size: 1rem;

    &:first-of-type {
      margin-bottom: ${({ theme }) => theme.margin.sm};
      margin-right: ${({ theme }) => theme.margin.sm};
    }
  }
`

const StyledAgeConsent = styled.section`
  margin-top: -1.1rem; // due to header's margin-bottom

  @media ${deviceUtils.DEVICE_MD_UP} {
    margin-top: -1rem;
  }
`
const ageConsentSteps = {
  AGE_VERIFICATION: 'AGE_VERIFICATION',
  AGE_NOT_AUTHORIZED: 'AGE_NOT_AUTHORIZED',
}

const AgeConsent = ({ imageMobile, imageDesktop, setAgeAllowed }: AgeConsentProps) => {
  const [step, setStep] = useState<string>(ageConsentSteps.AGE_VERIFICATION)

  const renderAgeVerification = () => (
    <>
      <h3>
        <Translate id="age_consent.title" />
      </h3>
      <p>
        <Translate id="age_consent.text" />
      </p>
      <Button size="small" onClick={() => setAgeAllowed(true)}>
        <Translate id="age_consent.button.affirmative" />
      </Button>
      <Button size="small" onClick={() => setStep(ageConsentSteps.AGE_NOT_AUTHORIZED)}>
        <Translate id="age_consent.button.negative" />
      </Button>
    </>
  )

  const renderAgeNotAuthorized = () => (
    <>
      <h3>
        <Translate id="age_not_consent.title" />
      </h3>
      <p>
        <Translate id="age_not_consent.text" />
      </p>
      <a href="https://www.beeksebergen.nl">
        <Button size="small">
          <Translate id="age_not_consent.button" />
        </Button>
      </a>
    </>
  )

  return (
    <StyledAgeConsent>
      <Center>
        <ImageWrapper>
          <ImageContainer imageDesktop={imageDesktop} imageMobile={imageMobile} />
        </ImageWrapper>
        <AgeConsentContent>
          {step === ageConsentSteps.AGE_VERIFICATION ? renderAgeVerification() : renderAgeNotAuthorized()}
          <MoreInfo>
            <span>
              <Translate id="age_consent.nix18.see_more" />
            </span>
            <img src="/beeksebergen/nix18.svg" />
          </MoreInfo>
        </AgeConsentContent>
      </Center>
    </StyledAgeConsent>
  )
}

export default AgeConsent
