import React from 'react'
import OrderConfirmationLightSafari from '../OrderConfirmation.lightsafari'
import { ACCOUNT_NAMES } from '../../data/accounts'
import OrderConfirmation from '../OrderConfirmation'
import { Page } from '../../api/pageApi'

type Props = {
  accountName: ACCOUNT_NAMES
  page: Page
}
const ConfirmationPage = ({ accountName, page }: Props) => {
  if (accountName === ACCOUNT_NAMES.LIGHTSAFARI) {
    return <OrderConfirmationLightSafari page={page} />
  }

  return <OrderConfirmation page={page} />
}

export default ConfirmationPage
