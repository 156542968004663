import React from 'react'
import * as Sentry from '@sentry/browser'
import { connect } from 'react-redux'
import { Rect, Alert, AlertType, Translate, InfoIcon } from '@jstack/libema-design-system'
import { getExpositionProducts, Product, Timeframe } from '../../api/productApi'
import { AppState } from '../../redux/reducers'
import { getAccount, getLocale } from '../../selectors/envSelectors'
import { Moment } from 'moment'
import { Exposition, getExposition } from '../../api/expositionApi'
import styled from 'styled-components'
import * as CardBox from '../common/CardBox'
import TimeframeSelector from './TimeframeSelector'
import Loader from '../loaders/Loader'
import { Account } from '../../redux/envReducer'
import ActivityProductsForm from './ActivityProductsForm'
import { getTotalTicketsByExposition } from '../../selectors/orderSelectors'

interface ActivityBlockBaseProps {
  expositionId: string
  mainTimeframe: Timeframe
}

interface ActivityBlockProps extends ActivityBlockBaseProps {
  account: Account | null
  locale: string
  totalTickets: number
}

interface ActivityBlockState {
  activity?: Exposition
  isPending: boolean
  hasProductError: boolean
  selectedTimeframe?: Timeframe
  loadingProducts: boolean
  products: Product[]
}

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;

  ${CardBox.Title} {
    flex: auto;
  }
`

class ActivityBlock extends React.PureComponent<ActivityBlockProps, ActivityBlockState> {
  state: ActivityBlockState = {
    activity: undefined,
    isPending: false,
    hasProductError: false,
    selectedTimeframe: undefined,
    loadingProducts: false,
    products: [],
  }

  componentDidMount = () => {
    this.fetchExposition()
  }

  componentDidUpdate(prevProps: ActivityBlockProps, prevState: ActivityBlockState) {
    if (prevState.selectedTimeframe?.id !== this.state.selectedTimeframe?.id) {
      this.fetchProducts()
    }
  }

  setTimeFrame = (timeframe?: Timeframe) => {
    if (this.state.selectedTimeframe?.id !== timeframe?.id) {
      this.setState({ selectedTimeframe: timeframe })
    }
  }

  fetchExposition() {
    const { account, expositionId, locale } = this.props

    if (account?.id) {
      getExposition(account.id, expositionId, locale)
        .then(({ data }) => this.setState({ activity: data }))
        .catch((e) => Sentry.captureException(e))
        .finally(() => this.setState({ isPending: false }))
    }
  }

  fetchProducts() {
    const { account, expositionId, locale } = this.props

    if (!account?.id || !this.state.selectedTimeframe?.from) {
      return
    }

    this.setState({ loadingProducts: true })

    getExpositionProducts(account.id, expositionId, { locale, date: this.state.selectedTimeframe?.from })
      .then((response) => {
        this.setState({ loadingProducts: false, products: response.data.items })
      })
      .catch((e) => {
        this.setState({ hasProductError: true })
        Sentry.captureException(e)
      })
  }

  render() {
    const { expositionId, mainTimeframe, account } = this.props
    const { activity, loadingProducts, hasProductError, products, selectedTimeframe } = this.state

    const showLoader = loadingProducts && !hasProductError
    const showForm = !loadingProducts && !hasProductError && products.length > 0 && selectedTimeframe?.id
    const showError = hasProductError

    if (!activity) {
      return (
        <Alert type={AlertType.ERROR} style={{ marginBottom: '1rem' }}>
          <Translate id="tickets.activities.error" values={{ expositionId, phoneNumber: account?.phone_number }} />
        </Alert>
      )
    }

    if (this.state.isPending) {
      return <Rect height={300} margin="0" />
    }

    const tooltip = undefined

    return (
      <>
        <CardBox.Wrapper data-class="product-row">
          <Header>
            <CardBox.Title>{activity.name}</CardBox.Title>
            {tooltip && <InfoIcon placement="top" content={tooltip} />}
          </Header>

          {activity.description && <CardBox.SubTitle>{activity.description}</CardBox.SubTitle>}
          <TimeframeSelector
            date={mainTimeframe.from}
            expositionId={expositionId}
            value={selectedTimeframe?.id}
            timeFrameAlmostSoldOutThreshold={account?.timeFrameAlmostSoldOutThreshold || 20}
            onChange={this.setTimeFrame}
          />
          {showLoader && <Loader />}
          {showError && (
            <Alert type={AlertType.ERROR}>
              <Translate id="error.products_fetch_failed" values={{ phoneNumber: account?.phone_number }} />
            </Alert>
          )}
        </CardBox.Wrapper>
        {showForm && (
          <ActivityProductsForm
            products={products}
            timeframe={selectedTimeframe as Timeframe}
            activity={activity}
            max={this.state.activity?.maxTickets}
            mainTimeframe={mainTimeframe}
          />
        )}
        {this.state.activity?.maxTickets && this.props.totalTickets === this.state.activity?.maxTickets && (
          <Alert type={AlertType.WARNING}>
            <Translate id="error.max_tickets_reached" />
          </Alert>
        )}
        {this.state.activity?.maxTickets && this.props.totalTickets > this.state.activity?.maxTickets && (
          <Alert type={AlertType.ERROR}>
            <Translate id="error.max_tickets_exceeded" />
          </Alert>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: AppState, props: ActivityBlockBaseProps) => {
  return {
    account: getAccount(state),
    locale: getLocale(state),
    totalTickets: getTotalTicketsByExposition(state, props.expositionId),
  }
}

export default connect(mapStateToProps)(ActivityBlock)
