import { SET_TIMEFRAMES, CLEAR_TIMEFRAMES } from '../actions/productActions'
import { Timeframe } from '../api/productApi'

interface SetTimeframesAction {
  type: typeof SET_TIMEFRAMES
  timeframes: object
}

interface CLearTimeframesAction {
  type: typeof CLEAR_TIMEFRAMES
}

type Action = SetTimeframesAction | CLearTimeframesAction

export interface TimeframesState {
  [id: string]: Timeframe
}

export const initialState: TimeframesState = {}

export default (state: TimeframesState = initialState, action: Action) => {
  switch (action.type) {
    case SET_TIMEFRAMES: {
      return {
        ...state,
        ...action.timeframes,
      }
    }
    case CLEAR_TIMEFRAMES: {
      return {}
    }
    default: {
      return state
    }
  }
}
