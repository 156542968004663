import { AxiosResponse, AxiosPromise } from 'axios'
import * as Sentry from '@sentry/browser'
import cartApi from '../api/cartApi'
import { OrderProduct, Order, createOrder, PostOrderResponse, getOrderStatus } from '../api/orderApi'
import { getProducts, Product } from '../api/productApi'
import { ThunkResult } from '../redux/types'
import { CustomerState } from '../redux/orderReducer'
import { getOrderProducts, getCartItems, getOrderProductQuantity } from '../selectors/orderSelectors'
import { getAccountId, getLocale } from '../selectors/envSelectors'
import productUtils from '../utils/productUtils'
import { normalize } from '../utils/normalizeUtils'
import { setProducts } from './productActions'
import analyticsUtils from '../utils/analyticsUtils'

export const RESET_STATE = 'order/resetState'
export const SET_CART = 'order/setCart'
export const SET_CART_PENDING = 'order/setCartPending'
export const SET_CUSTOMER = 'order/setCustomer'
export const SET_ID = 'order/setId'
export const SET_ORDER_PRODUCTS = 'order/setOrderProducts'
export const UPDATE_ORDER_PRODUCT = 'order/updateOrderProduct'

export interface ResetStateAction {
  type: typeof RESET_STATE
}

export const resetState = (): ResetStateAction => ({
  type: RESET_STATE
})

export interface SetIdAction {
  type: typeof SET_ID
  payload: { id: string }
}

export const setId = (id: string): SetIdAction => ({
  type: SET_ID,
  payload: { id },
})

export interface SetCartAction {
  type: typeof SET_CART
  cart: object
}

export const setCart = (cart: object): SetCartAction => ({
  type: SET_CART,
  cart
})

export interface SetCartPendingAction {
  type: typeof SET_CART_PENDING
  payload: boolean
}

export const setCartPending = (isPending: boolean): SetCartPendingAction => ({
  type: SET_CART_PENDING,
  payload: isPending
})

export interface SetOrderProductsAction {
  type: typeof SET_ORDER_PRODUCTS
  payload: OrderProduct[]
}

export const setOrderProducts = (products: OrderProduct[]): SetOrderProductsAction => ({
  type: SET_ORDER_PRODUCTS,
  payload: products,
})

export interface UpdateOrderProductAction {
  type: typeof UPDATE_ORDER_PRODUCT
  payload: OrderProduct
}

export interface SetCustomerAction {
  type: typeof SET_CUSTOMER
  payload: CustomerState
}

export const setCustomer = (customer: CustomerState) => ({
  type: SET_CUSTOMER,
  payload: customer,
})

export const updateOrderProduct = (product: OrderProduct): ThunkResult<void> => (dispatch, getState) => {
  const state = getState()
  const quantity = product.quantity - getOrderProductQuantity(state, product.id)

  analyticsUtils.updateCart(product, quantity)

  dispatch({
    type: UPDATE_ORDER_PRODUCT,
    payload: { ...product },
  })
}

export const updateCart = (lock: boolean = false): ThunkResult<Promise<void>> =>
  (dispatch, getState) => {
    const state = getState()
    const locale = getLocale(state)
    dispatch(setCartPending(true))

    const accountId = getAccountId(state)
    let products = getOrderProducts(state)

    if (lock) {
      const cartItems = getCartItems(state)
      products = productUtils.mergeProductsWithCartItems(products, cartItems)
    }
    const data = { account_id: accountId, lock, products, locale }
    return cartApi.validateCart(data)
      .then((response: AxiosResponse) => {
        if (!response.data) {
          throw new Error('An empty response was received from the post /carts call.')
        }
        dispatch(setCart(response.data))
      })
  }

export const removeFromCart = (productId: string, timeframeId?: string): ThunkResult<void> => (dispatch, getState) => { // tslint:disable-line max-line-length
  const state = getState()
  const orderProducts = getOrderProducts(state)

  const product = orderProducts.find(p => p.id === productId)

  if (product) {
    analyticsUtils.removeFromCart(product)
  }

  const products: OrderProduct[] = productUtils.removeFromList(orderProducts, productId, timeframeId)

  dispatch(setOrderProducts(products))
}

export const confirmOrder = (data: Order): ThunkResult<AxiosPromise<PostOrderResponse>> =>
  (dispatch, getState) => {
    const state = getState()

    const accountId = getAccountId(state) || ''
    const locale = getLocale(state)
    const orderProducts = getOrderProducts(state)
    const cartItems = getCartItems(state)

    const products = productUtils.mergeProductsWithCartItems(orderProducts, cartItems)
    if (products.length < 1) {
      const err = new Error('The order was confirmed without any products')
      Sentry.addBreadcrumb({ category: 'data', data: { orderProducts, cartItems }, level: Sentry.Severity.Info})
      Sentry.captureException(err)

      return Promise.reject(err)
    }

    const postData = { ...data, products, account_id: accountId, locale }
    return createOrder(accountId, postData)
      .then(res => {
        analyticsUtils.checkoutFinish()
        return res;
      })
      .catch((err) => {
        Sentry.addBreadcrumb({ category: 'data', data: postData, level: Sentry.Severity.Info })
        Sentry.captureException(err)
        throw err
      })
  }

/**
 * Validates a voucher code and returns a product that matches the voucher.
 * Once the voucher was valid and a product is returned, the product is automatically
 * added to the order.
 */
export const validateVoucher = (params: { voucher: string }): ThunkResult<Promise<{ voucher_status: number }>> =>
  (dispatch, getState) => {
    const state = getState()
    const locale = state.env.locale
    const accountId = getAccountId(state) || ''

    return getProducts(accountId, { locale, ...params})
      .then((res: AxiosResponse) => {
        if (typeof res.data.items !== 'undefined' && res.data.items.length > 0) {
          const normalized = normalize(res.data.items.map((item: Product) => ({ ...item, voucher: params.voucher })), 'id')

          dispatch(setProducts(normalized))
        }
        return res.data
      })
  }

export interface OrderStatusResult {
  status: 'undefined' | 'pending' | 'paid'
}

export const verifyOrderStatus = (): ThunkResult<Promise<OrderStatusResult>> =>
  (dispatch, getState) => {
    const state = getState()
    if (typeof state.order.id === 'undefined') {
      return Promise.resolve({ status: 'undefined' })
    }
    const accountId = getAccountId(state) || ''

    return getOrderStatus(accountId, state.order.id)
      .then((response) => {
        if (response.data.status === 'paid') {
          dispatch(resetState())
        }

        return response.data
      })
  }
