import { combineReducers } from 'redux';
import order, { initialState as initialOrderState } from './orderReducer';
import env, { initialState as initialEnvState } from './envReducer';
import products, { initialState as initialProductsState } from './productReducer';
import timeframes, { initialState as initialTimeframesState } from './timeframeReducer'
import exposition, { initialState as initialExpositionState } from './expositionReducer'

const reducer = combineReducers({
  order,
  env,
  timeframes,
  products,
  exposition
})

export type AppState = ReturnType<typeof reducer>;

export const getInitialAppState = (): AppState => ({
  env: { ...initialEnvState },
  order: { ...initialOrderState },
  products: { ...initialProductsState },
  timeframes: { ...initialTimeframesState },
  exposition: initialExpositionState,
})

export default reducer;
